import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { User } from '@app/users/shared';
import { Community, CommunityMember } from '../../shared';
import { CommunityService } from '../../shared/community.service';
import { CommunityMemberService } from '../../shared/community-member.service';

@Component({
  selector: 'app-community-members-tab',
  templateUrl: './community-members-tab.component.html',
  styleUrls: ['./community-members-tab.component.css'],
})
export class CommunityMembersTabComponent implements OnInit {
  community$!: Observable<Community>;
  members$!: Observable<CommunityMember[]>;
  resetForm$ = new Subject<void>();
  savingMember = false;

  constructor(
    private communityService: CommunityService,
    private communityMemberService: CommunityMemberService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.community$ = this.getCommunity();
    this.members$ = this.getCommunityMembers();
  }

  createMember(
    community: Community,
    {
      member,
      user,
      password,
    }: { member: CommunityMember; user: User; password: string },
  ): void {
    this.savingMember = true;

    this.communityMemberService
      .activateMember(community, member, user, password)
      .then((communityMember: CommunityMember) => {
        this.savingMember = false;
        this.resetForm$.next();

        this.openSnackBar(
          `${communityMember.email} has been added to your community.`,
        );
      })
      .catch((error) => {
        console.error(error);
        this.openSnackBar(error, 30000);
      });
  }

  removeMember(member: CommunityMember): void {
    this.communityMemberService
      .deactivateMember(member)
      .then(() => {
        this.openSnackBar(
          `${member.email} has been removed from your community.`,
        );
      })
      .catch((error) => {
        console.error(error);
        this.openSnackBar(error, 30000);
      });
  }

  updateMember(member: CommunityMember): void {
    this.communityMemberService
      .set(member)
      .then(() => {
        this.openSnackBar(`${member.email} has been updated.`);
      })
      .catch((error) => {
        console.error(error);
        this.openSnackBar(error, 30000);
      });
  }

  getCommunityMembers(): Observable<CommunityMember[]> {
    return this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.communityMemberService.getActiveCommunityMembers(
          params.get('communityKey') || '',
        ),
      ),
    );
  }

  getCommunity(): Observable<Community> {
    return this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.communityService.getCommunity(params.get('communityKey') || ''),
      ),
    );
  }

  openSnackBar(message: string, duration = 3000): void {
    this.snackBar.open(message, 'Dismiss', { duration });
  }
}
