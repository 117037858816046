import { cwsUrl } from '@env';

export const formatError = (message: string, error: Error): string => {
  const errorName = message.replace(/^Error: /, '');

  if (error?.stack) {
    const stack = error.stack
      // strip the leading "Error: " from the stack trace
      .replace(/^Error: /, '')
      // strip the message from the stack trace, if present
      .replace(`${message}\n`, '')
      // strip leading spaces
      .replace(/^ +/gm, '')
      // strip all leading "at " for each frame
      .replace(/^at /gm, '')
      // replace long urls with just the last segment: `filename:line:column`
      .replace(/(?: \(|@)http.+\/([^/)]+)\)?(?:\n|$)/gm, '@$1\n')
      // replace "eval code" in Edge
      .replace(/ *\(eval code(:\d+:\d+)\)(?:\n|$)/gm, '@???$1\n');

    return `${errorName}\n${stack}`.substr(0, 150);
  }

  return errorName;
};

export const gotoContactPage = (): void => {
  document.location.href = `${cwsUrl}/contact`;
};
