import packageJson from '../../package.json';

// corporate website
export const cwsUrl = 'https://rethinkreading.com';

// sightwords config
export const sightwordsTotal = 100;
export const sightwordsPerLevel = 10;

export const shared = {
  appVersion: packageJson.version,
};

export * from '../settings/avatars';
