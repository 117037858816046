// Credit: https://stackoverflow.com/questions/40418804/access-sass-values-colors-from-variables-scss-in-typescript-angular2-ionic2

import { Component } from '@angular/core';

export const PREFIX = '--';

@Component({
  selector: 'app-sass-helper',
  template: '<div></div>',
})
export class SassHelperComponent {
  // Read the custom property of body section with given name:

  readProperty(name: string): string {
    const bodyStyles = window.getComputedStyle(document.body);
    let result = bodyStyles.getPropertyValue(PREFIX + name);
    if (result) {
      result = result.trim();
    }
    return result;
  }

  readColorAsArray(name: string): number[] | null {
    const colorStr = this.readProperty(name);
    if (colorStr) {
      const colorArray = this.parseColor(colorStr);
      return colorArray;
    }
    return null;
  }

  // Credit: https://stackoverflow.com/questions/1418050/string-strip-for-javascript

  parseColor(input: string): number[] {
    if (input.substr(0, 1) === '#') {
      const collen = (input.length - 1) / 3;
      const fact = [17, 1, 0.062272][collen - 1];
      return [
        Math.round(parseInt(input.substr(1, collen), 16) * fact),
        Math.round(parseInt(input.substr(1 + collen, collen), 16) * fact),
        Math.round(parseInt(input.substr(1 + 2 * collen, collen), 16) * fact),
      ];
    }
    return input
      .split('(')[1]
      .split(')')[0]
      .split(',')
      .map((v) => Math.round(+v));
  }
}
