import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { User } from '@app/users/shared';
import { CommunityMember } from '../../shared';

@Component({
  selector: 'app-community-member-form',
  templateUrl: './community-member-form.component.html',
  styleUrls: ['./community-member-form.component.css'],
})
export class CommunityMemberFormComponent {
  @Input() isCommunityAdmin$!: Observable<boolean>;
  @Input() saving = false;
  @Input() title!: string;
  @Input() member!: CommunityMember;
  @Input() user!: User;

  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();

  languages = [
    { value: 'en-AU', viewValue: 'English (Australia)' },
    { value: 'en-CA', viewValue: 'English (Canada)' },
    { value: 'en-GH', viewValue: 'English (Ghana)' },
    { value: 'en-GB', viewValue: 'English (Great Britain)' },
    { value: 'en-IN', viewValue: 'English (India)' },
    { value: 'en-IE', viewValue: 'English (Ireland)' },
    { value: 'en-KE', viewValue: 'English (Kenya)' },
    { value: 'en-NZ', viewValue: 'English (New Zealand)' },
    { value: 'en-NG', viewValue: 'English (Nigeria)' },
    { value: 'en-PH', viewValue: 'English (Philippines)' },
    { value: 'en-SG', viewValue: 'English (Singapore)' },
    { value: 'en-ZA', viewValue: 'English (South Africa)' },
    { value: 'en-TZ', viewValue: 'English (Tanzania)' },
    { value: 'en-US', viewValue: 'English (United States)' },
  ];

  submit(): void {
    const member = { ...this.member };

    // Convert birthday from local time zone to UTC.
    const m = moment(member.birthday);
    member.birthday = m.utc().add(m.utcOffset(), 'm').format();

    this.save.emit({
      member,
      user: this.user,
    });
  }

  goBack(): void {
    this.cancel.emit();
  }
}
