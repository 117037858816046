/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

import { shared } from './shared';

export * from './shared';

export const environment = {
  ...shared,
  appName: 'Rethink Reading (Staging)',
  environment: 'staging',
  production: false,
  serviceWorker: true,
};

export const webAppUrl = 'https://rethinkreading.dev';

export const authConfig = {
  clever: {
    apiRoot: 'https://api.clever.com/v2.0',
    popupUrl: `${webAppUrl}/cleverPopup`,
    redirectUrl: `${webAppUrl}/cleverRedirect`,
    tokenUrl: `${webAppUrl}/api/v1/cleverToken`,
  },
};

export const firebaseConfig = {
  apiKey: 'AIzaSyA5iXbbkfAsvqnW7ItGuuqzatcUpd_Ed78',
  appId: '1:986096864619:web:6ddce693710e292315902e',
  authDomain: 'rethink-reading-staging.firebaseapp.com',
  clientId:
    '986096864619-omdv2q66o197rssvdgpdlblfilvg9tnk.apps.googleusercontent.com',
  databaseURL: 'https://rethink-reading-staging.firebaseio.com',
  projectId: 'rethink-reading-staging',
  storageBucket: 'rethink-reading-staging.appspot.com',
  messagingSenderId: '986096864619',
  measurementId: 'G-0M9BQSR80M',
};

export const fixturesCommunityMemberKey = 'LZhQcAtu0PPsYjqGtLUU';

// stripe API Publishable key
export const stripeKey = 'pk_test_viUoVl2crkmJcWZfMe8A449z';

export const streamingServiceUrl =
  'https://rethink-reading-streaming-server-txdrqbsk7q-uw.a.run.app';

export const sightWordsInstructionsAudioUrl =
  'https://firebasestorage.googleapis.com/v0/b/rethink-reading-staging.appspot.com/o/exam-question-media%2Faudio%2FSightWordsInstructions.mp3?alt=media&token=9d4844e5-5c6b-45fd-bb52-865181d60a92';
