import {
  CollectionReference,
  DocumentData,
  Query,
} from '@angular/fire/compat/firestore';

/**
 * Provide a sane default upper limit for number of database records returned (when not specified).
 */
export const defaultFirestoreQueryLimit = (
  ref: CollectionReference,
): Query<DocumentData> => ref.limit(1000);

/**
 * @enumerable() decorator sets a class / object property as either enumerable or non-enumerable.
 *
 * @param value true|false
 */
export const enumerable =
  (value: boolean) =>
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy
    target: any,
    propertyKey: string,
  ): void => {
    const descriptor =
      Object.getOwnPropertyDescriptor(target, propertyKey) || {};
    if (descriptor.enumerable !== value) {
      descriptor.enumerable = value;
      Object.defineProperty(target, propertyKey, descriptor);
    }
  };

/**
 * Delete any undefined properties.
 * Why? Firebase will throw an error and refuse to save objects with undefined properties.
 */
export const sanitizeProperties = <T>({ ...data }: T): T => {
  for (const prop in data) {
    if (data[prop] === undefined) {
      // eslint-disable-next-line no-param-reassign -- Legacy
      delete data[prop];
    }
  }

  return data;
};
