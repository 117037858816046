import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppMaterialModule } from './app-material.module';
import {
  BadgeComponent,
  BufferBarComponent,
  ConfirmationDialogComponent,
  CountdownComponent,
  ImageComponent,
  MessageDividerComponent,
  SassHelperComponent,
  SpinnerComponent,
  TableComponent,
} from './components';
import { PasswordMatcherDirective } from './directives';
import {
  KeysPipe,
  ObservablePipe,
  OrdinalPipe,
  PluckPipe,
  SafeNumberPipe,
  ShufflePipe,
  SliceWordsPipe,
  StripPassagePipe,
} from './pipes';

const sharedModules = [
  // components
  BadgeComponent,
  BufferBarComponent,
  ConfirmationDialogComponent,
  CountdownComponent,
  ImageComponent,
  MessageDividerComponent,
  SassHelperComponent,
  SpinnerComponent,
  TableComponent,

  // directives
  PasswordMatcherDirective,

  // pipes
  KeysPipe,
  ObservablePipe,
  OrdinalPipe,
  PluckPipe,
  SafeNumberPipe,
  ShufflePipe,
  SliceWordsPipe,
  StripPassagePipe,
];

@NgModule({
  imports: [
    // import order matters
    CommonModule,

    // Angular extensions
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    // Third-party modules

    // Custom modules
    AppMaterialModule,
  ],
  declarations: sharedModules,
  exports: [
    // modules
    CommonModule,
    RouterModule,

    // Angular extensions
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    // Third-party modules
    MomentModule,
    NgxPaginationModule,

    // Custom modules
    AppMaterialModule,

    ...sharedModules,
  ],
})
export class SharedModule {}
