import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireAnalyticsModule,
  APP_NAME,
  APP_VERSION,
  COLLECTION_ENABLED,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxWebstorageModule } from 'ngx-webstorage';

import { CommunityExportsModule } from '@app/community/community-exports.module';
import { CommunityFirestoreService } from '@core/firebase/community-firestore.service';
import { environment, firebaseConfig } from '@env';
import { AppMaterialModule } from '@shared/app-material.module';
import { LoadingSpinnerComponent } from './components';
import { GlobalErrorHandler } from './services/global-error-handler.service';

@NgModule({
  imports: [
    // module import order matters for NgModules
    CommonModule,

    // Angular modules
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.serviceWorker,
    }),

    // Angular extensions
    // import order matters for these AngularFire extensions
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    AngularFireStorageModule,
    FlexLayoutModule,

    // Third-party modules
    NgxWebstorageModule.forRoot({
      prefix: `app-rethink-reading${
        environment.production ? '' : `-${environment.environment}`
      }`,
      separator: ':',
    }),

    // Custom modules
    AppMaterialModule,
    CommunityExportsModule,
  ],
  providers: [
    CommunityFirestoreService, // explicitly provided since Angular won't inject inherited services
    GlobalErrorHandler,
    ScreenTrackingService,
    UserTrackingService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: APP_NAME, useValue: environment.appName },
    { provide: APP_VERSION, useValue: environment.appVersion },
    {
      provide: COLLECTION_ENABLED,
      useValue: environment.environment !== 'local',
    },
  ],
  declarations: [LoadingSpinnerComponent],
  exports: [
    FlexLayoutModule,
    AppMaterialModule,
    CommunityExportsModule,
    LoadingSpinnerComponent,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in AppModule only!',
      );
    }
  }
}
