import { FirebaseAbstract } from '@core/firebase';

export class AccessRequest extends FirebaseAbstract {
  communityKey: string | undefined;
  userKey: string | undefined;

  constructor(communityKey?: string, userKey?: string) {
    super();

    this.communityKey = communityKey;
    this.userKey = userKey;
  }
}
