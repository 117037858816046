import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

export const passwordMatcher = (
  form: AbstractControl,
): { nomatch: boolean } | null => {
  // pass if either password or confirm inputs are missing
  if (!form.get('password') || !form.get('confirm')) {
    return null;
  }
  // pass if both password and confirm inputs are empty
  if (
    !!form.get('password')?.value === false &&
    !!form.get('confirm')?.value === false
  ) {
    return null;
  }
  // pass password and confirm inputs match, otherwise invalidate
  return form.get('password')?.value === form.get('confirm')?.value
    ? null
    : { nomatch: true };
};

@Directive({
  selector: '[appPasswordMatchValidator]',
  providers: [
    { provide: NG_VALIDATORS, multi: true, useValue: passwordMatcher },
  ],
})
export class PasswordMatcherDirective {}
