<div class="community-welcome-container">

  <div class="community-create">
    <a mat-raised-button color="primary" routerLink="/community/new" class="button-full">Create New Community</a>
  </div>

  <mat-divider class="static-divider"></mat-divider>
  <div class="or">OR</div>

  <div class="community-join">
    <h5 class="leading-loose">Join a community</h5>

    <form class="community-join-form" (ngSubmit)="continue()" #communityJoinForm="ngForm">
      <div class="mb-2">Enter your community's <span class="semi-bold">Rethink Reading URL:</span></div>
      <mat-form-field class="community-name-input-field" hideRequiredMarker appearance="outline">
        <input matInput [(ngModel)]="communityName" placeholder="your-community-url" name="communityName" required>
        <span matSuffix><b>.rethinkreading.com</b></span>
      </mat-form-field>
      <button mat-raised-button color="accent" [disabled]="!communityJoinForm.form.valid">CONTINUE</button>
    </form>
  </div>

</div>
