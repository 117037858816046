<!-- <cl-image [public-id]="path" [attr.height]="height || null" [attr.width]="width || null">
  <cl-transformation
    [attr.height]="height ? height * 2 : null"
    [attr.width]="width ? width * 2 : null"
    [attr.crop]="crop"
    [attr.quality]="quality">
  </cl-transformation>
</cl-image> -->

<img src="/assets/images/{{ path }}" [attr.height]="height || null" [attr.width]="width || null" />
