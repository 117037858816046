<mat-card>
  <mat-card-content>
    <h3>Congratulations, you just created a new community!</h3>
    <p>
      Now is a good time to grow your community by adding some members.
    </p>
    <p>
      Once you're done with that, head over to
      <a routerLink="/dashboard">the dashboard</a> to get started.
    </p>
  </mat-card-content>
</mat-card>
