import { animate, style, transition, trigger } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  LegacySimpleSnackBar as SimpleSnackBar,
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
} from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import {
  fromEvent as observableFromEvent,
  merge as observableMerge,
  Observable,
  of as observableOf,
} from 'rxjs';
import { filter, mapTo } from 'rxjs/operators';

import { AuthService } from '@core/services/auth.service';
import { cwsUrl as publicUrl, environment } from '@env';
import { SidenavView } from '@shared/models';
import { addInstallPromptListener } from '@shared/util';
import { Community } from './community/shared';
import { CommunitySessionService } from './community/shared/community-session.service';
import { User } from './users/shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('loadingTrigger', [
      transition(':leave', [animate('400ms 200ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  cwsUrl = publicUrl;
  env = environment;

  activeCommunities$!: Observable<Community[]>;
  title = 'Rethink Reading';
  loading = true;

  sidenavViews: SidenavView[] = [];
  adminViews: SidenavView[] = [
    {
      name: 'Assignments',
      // description: 'Manage exam assignments',
      icon: 'assignment',
      link: '/admin/assignments',
    },
    {
      name: 'Classrooms',
      // description: 'View and manage classrooms',
      icon: 'collections_bookmark',
      link: '/admin/classrooms',
    },
    {
      name: 'Communities',
      // description: 'Manage communities',
      icon: 'account_box',
      link: '/community',
    },
    {
      name: 'Curricula',
      // description: 'Manage communities',
      icon: 'timeline',
      link: '/admin/curricula',
    },
    {
      name: 'Passages',
      // description: 'Manage exam passages',
      icon: 'speaker_notes',
      link: '/admin/passages',
    },
    {
      name: 'Exam Setup',
      // description: 'Configure exam details',
      icon: 'build',
      link: '/admin/setup',
      filled: true,
    },
    {
      name: 'Exams',
      // description: 'Review and grade exams',
      icon: 'assessment',
      link: '/admin/exams',
    },
    {
      name: 'Users',
      // description: 'View and manage profiles',
      icon: 'people',
      link: '/admin/users',
    },
  ];
  anonymousViews: SidenavView[] = [
    {
      name: 'Log in',
      // description: 'Log in and register',
      icon: 'community_circle',
      link: '/welcome/login',
    },
  ];
  authenticatedViews: SidenavView[] = [
    {
      name: 'Dashboard',
      // description: 'View your dashboard',
      icon: 'home',
      link: '/',
      filled: true,
    },
    {
      name: 'Curriculum',
      icon: 'account_tree',
      link: '/curriculum',
    },
    {
      name: 'Classrooms',
      // description: 'Visit your classrooms',
      icon: 'collections_bookmark',
      link: '/classrooms',
    },
    {
      name: 'Assignments',
      // description: 'Assigned exams',
      icon: 'assignment',
      link: '/exams',
    },
    {
      name: 'Fluency',
      // description: 'Practice reading a story aloud',
      icon: 'headset_mic',
      link: '/exams/practice',
      filled: true,
    },
    {
      name: 'Reading Level',
      // description: 'Practice improving your reading level',
      icon: 'library_books',
      link: '/exams/practice/readinglevel',
    },
    {
      name: 'Comprehension',
      // description: 'Practice your reading comprehension',
      icon: 'chrome_reader_mode',
      link: '/exams/practice/comprehension',
    },
    {
      name: 'Phonics',
      // description: 'Practice reading sight words',
      icon: 'record_voice_over',
      link: '/exams/practice/phonics',
      // filled: true,
    },
  ];

  mobileQuery: MediaQueryList;
  mobileQueryListener: () => void;

  tabletQuery: MediaQueryList;
  tabletQueryListener: () => void;
  online$: Observable<boolean>;
  snackBarRef!: MatSnackBarRef<SimpleSnackBar>;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private communitySessionService: CommunitySessionService,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 599px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();

    this.tabletQuery = media.matchMedia('(max-width: 1600px)');
    this.tabletQueryListener = () => changeDetectorRef.detectChanges();

    if (this.mobileQuery.addEventListener) {
      this.mobileQuery.addEventListener('change', this.mobileQueryListener);
      this.tabletQuery.addEventListener('change', this.tabletQueryListener);
    } else {
      // fallback for browser compatibility
      this.mobileQuery.addListener(this.mobileQueryListener);
      this.tabletQuery.addListener(this.tabletQueryListener);
    }

    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    this.online$ = observableMerge(
      observableOf(navigator.onLine),
      observableFromEvent(window, 'online').pipe(mapTo(true)),
      observableFromEvent(window, 'offline').pipe(mapTo(false)),
    );

    this.online$.subscribe((isOnline: boolean) => {
      if (!isOnline) {
        this.displayOfflineMessage();
      }
      if (isOnline && this.snackBarRef) {
        this.hideOfflineMessage();
      }
    });

    if (!environment.production) {
      // Alias Angular router for Cypress, and other ease-of-debugging purposes.
      (window as unknown as Window & { router: Router }).router = this.router;
    }
  }

  ngOnInit(): void {
    this.activeCommunities$ = this.communitySessionService.getCommunities();

    // Set sidenav views based on authenticated versus anonymous user
    this.authService.getUser$().subscribe((user: User) => {
      // Add the 'Reports' link if an uathenticated user can view exam reports
      if (
        user &&
        user.isActive &&
        !this.authenticatedViews.find((view) => view.name === 'Reports')
      ) {
        // Reports are broken, so hide the link for now. TODO: Fix reports.

        // if (this.classroomPermissionService.canViewExamReports()) {
        //   this.authenticatedViews.push({
        //     name: 'Reports',
        //     // description: 'View reports and statistics',
        //     icon: 'equalizer',
        //     link: '/reports',
        //   });
        // }

        this.sidenavViews = this.authenticatedViews;
      }
    });

    addInstallPromptListener();

    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      )
      .subscribe(this.promptUser);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
    this.tabletQuery.removeEventListener('change', this.tabletQueryListener);
  }

  authenticated(): boolean {
    return this.authService.authenticated();
  }

  isActive(): boolean {
    return !!(
      this.authService.authenticated() &&
      this.authService.getUser().isActive &&
      this.communitySessionService.sessionExists() &&
      this.communitySessionService.getActiveCommunitySnapshot()
    );
  }

  isGlobalAdmin(): boolean {
    return (
      this.authService.authenticated() &&
      this.authService.getUser().isGlobalAdmin
    );
  }

  logout(): void {
    this.authService.hardLogout();
  }

  // Hides loading spinner once navigation completes
  navigationInterceptor(event: RouterEvent): void {
    if (
      event instanceof NavigationCancel ||
      event instanceof NavigationEnd ||
      event instanceof NavigationError
    ) {
      this.loading = false;
    }
  }

  private displayOfflineMessage = (): void => {
    this.snackBarRef = this.snackBar.open(
      'You appear to be offline. Please check your connection.',
    );
  };

  private hideOfflineMessage = (): void => {
    this.snackBarRef.dismiss();
  };

  private promptUser = (): void => {
    const snackBarRef = this.snackBar.open(
      'New app update available!',
      'Reload',
    );

    snackBarRef.afterDismissed().subscribe(() => {
      document.location.reload();
    });
  };
}
