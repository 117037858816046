import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-community-welcome',
  templateUrl: './community-welcome.component.html',
  styleUrls: ['./community-welcome.component.css'],
})
export class CommunityWelcomeComponent {
  @Output() communityEntered = new EventEmitter();

  communityName!: string;

  continue(): void {
    this.communityEntered.emit(this.communityName);
  }
}
