export const addInstallPromptListener = (): void => {
  // Mimic BeforeInstallPromptEvent window event.
  // https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
  window.addEventListener(
    'beforeinstallprompt',
    (event: Event & Partial<{ prompt(): Promise<void> }>) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      event.preventDefault();
      // Prompt user to Add to Home Screen
      if (event.prompt) {
        // Disabling for now, consider reenabling in the future.
        // event.prompt().catch((reason) => console.warn(reason));
      }
    },
  );
};
