import { Pipe, PipeTransform } from '@angular/core';
import shuffle from 'lodash/fp/shuffle';

/**
 * @whatItDoes Randomly shuffles the order of an input array
 * @howToUse `expression | shuffle`
 * @description
 * Randomly shuffles the order of an input array, e.g. [1, 2, 3] could become [1, 3, 2]
 */
@Pipe({
  name: 'shuffle',
})
export class ShufflePipe implements PipeTransform {
  transform<T>(value: T[]): T[] {
    if (value == null) {
      return value;
    }
    if (value.length === 0) {
      return value;
    }

    return shuffle(value);
  }
}
