import { Pipe, PipeTransform } from '@angular/core';
import {
  from as observableFrom,
  isObservable,
  Observable,
  of as observableOf,
} from 'rxjs';

import { isIterable, isPromise } from '../util';

@Pipe({
  name: 'observable',
})
export class ObservablePipe implements PipeTransform {
  transform<T>(value: T): Observable<T> {
    if (isObservable(value)) {
      return value as Observable<T>;
    }

    if (isIterable(value) || isPromise(value)) {
      return observableFrom(value);
    }

    return observableOf(value);
  }
}
