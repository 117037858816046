import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { handleHttpError } from '@shared/util';

@Injectable({
  providedIn: 'root',
})
export class CloudFunctions {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
  ) {}

  async post<T>(url: string, data: Record<string, unknown>): Promise<T> {
    const token = await this.authService.getToken();
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
        .append('Content-Type', 'application/json'),
    };

    return lastValueFrom(
      this.httpClient.post<T>(url, data, options || {}),
    ).catch(handleHttpError);
  }

  async put<T>(url: string, data: Record<string, unknown>): Promise<T> {
    const token = await this.authService.getToken();
    const options = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
        .append('Content-Type', 'application/json'),
    };

    return lastValueFrom(this.httpClient.put<T>(url, data, options)).catch(
      handleHttpError,
    );
  }
}
