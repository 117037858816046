import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeNumber',
})
export class SafeNumberPipe extends DecimalPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) safeLocale: string) {
    super(safeLocale);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy
  override transform(
    value: any,
    digitsInfo: string = '',
    locale?: string,
  ): any {
    try {
      return super.transform(value, digitsInfo, locale);
    } catch (err) {
      return value;
    }
  }
}
