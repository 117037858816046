<!-- eslint-disable @angular-eslint/template/no-call-expression -->

<div *ngIf="communities$ | async; let communities; else loading">
  <mat-card>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search communities">
      <mat-icon matPrefix class="material-symbols-rounded search-icon">search</mat-icon>
    </mat-form-field>
  </mat-card>
  <mat-nav-list>
    <mat-list-item *ngFor="let community of filteredCommunities(communities)" [routerLink]="['/community', community.key]">
      <mat-icon mat-list-icon class="material-symbols-rounded">account_box</mat-icon>
      <p matLine>{{ community.name }} - {{ community.key }}</p>
      <button mat-icon-button class="community-delete" *ngIf="canDeleteCommunities$ | async" align="end" (click)="deleteCommunity(community, $event)">
        <mat-icon color="warn" class="material-symbols-rounded">delete</mat-icon>
      </button>
    </mat-list-item>
  </mat-nav-list>

  <div *ngIf="communities.length === 0">No results</div>
</div>

<ng-template #loading>
  <app-buffer-bar></app-buffer-bar>
</ng-template>
