import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';

import { ConfirmationDialogComponent } from '@shared/components';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  confirm(
    title: string,
    message: string = '',
    confirmButton = 'OK',
    dismissButton = 'CANCEL',
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.confirmButton = confirmButton;
    dialogRef.componentInstance.dismissButton = dismissButton;

    return dialogRef.afterClosed();
  }
}
