import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pluck' })
export class PluckPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy
  transform<T>(input: any[], key: string): T[] {
    try {
      return input.map((value) => value[key]);
    } catch {
      return input;
    }
  }
}
