import { Component, Input } from '@angular/core';

import { CommunityMember } from '@app/community/shared';

@Component({
  selector: 'app-community-member-view',
  templateUrl: './community-member-view.component.html',
  styleUrls: ['./community-member-view.component.css'],
})
export class CommunityMemberViewComponent {
  @Input() member!: CommunityMember;
}
