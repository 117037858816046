/* eslint max-classes-per-file:["error", 3] -- Legacy */

import { FirebaseAbstract } from '@core/firebase';

export class Community extends FirebaseAbstract {
  name!: string;
  shortname!: string;

  customerId!: string;
  activeMembers!: number;
  discountPercent!: number;
  inviteCode!: string;
  seats!: number;

  isActive = true;
  hasOpenAccessPolicy = false;
}

export class CommunityBilling extends FirebaseAbstract {
  discountPercent!: number;
  inviteCode!: string;
  seats!: number;
}

export class UserCommunity {
  active!: Community;
  inactive: Community[] = [];
}
