import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import {
  CommunityMemberAvatarComponent,
  CommunityMemberListComponent,
  CommunityMemberSearchComponent,
  CommunityMemberViewComponent,
  CommunityMenuComponent,
} from './components';

@NgModule({
  imports: [SharedModule],
  declarations: [
    CommunityMemberAvatarComponent,
    CommunityMenuComponent,
    CommunityMemberSearchComponent,
    CommunityMemberListComponent,
    CommunityMemberViewComponent,
  ],
  exports: [
    CommunityMemberAvatarComponent,
    CommunityMenuComponent,
    CommunityMemberSearchComponent,
    CommunityMemberListComponent,
    CommunityMemberViewComponent,
  ],
})
export class CommunityExportsModule {}
