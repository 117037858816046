import { FirebaseAbstract } from '@core/firebase';

export class User extends FirebaseAbstract {
  firstName!: string;
  lastName!: string;
  bio!: string;
  birthday!: string;
  contactEmail!: string;
  email!: string;
  password!: string | undefined;
  gender!: string;
  photoUrl!: string;
  providerId!: string;
  year!: number;

  isActive = true;
  isGlobalAdmin = false;

  constructor(user?: Partial<User>) {
    super();

    if (user) {
      Object.assign(this, user);
    }
  }
}
