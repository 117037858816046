import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import {
  MAT_ICON_DEFAULT_OPTIONS,
  MatIconModule,
} from '@angular/material/icon';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MtxGridModule } from '@ng-matero/extensions/grid';

const legacyMaterialModules = [
  // includes legacy Angular Material modules used by this app
  MatLegacyAutocompleteModule,
  MatLegacyButtonModule,
  MatLegacyCardModule,
  MatLegacyCheckboxModule,
  MatLegacyDialogModule,
  MatLegacyFormFieldModule,
  MatLegacyInputModule,
  MatLegacyListModule,
  MatLegacyMenuModule,
  MatLegacyPaginatorModule,
  MatLegacyProgressBarModule,
  MatLegacyProgressSpinnerModule,
  MatLegacyRadioModule,
  MatLegacySelectModule,
  MatLegacySlideToggleModule,
  MatLegacySnackBarModule,
  MatLegacyTabsModule,
  MatLegacyTooltipModule,
];

const materialModules = [
  // includes subset of Angular Material modules used by this app
  ...legacyMaterialModules,
  MatBadgeModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatToolbarModule,
  MtxGridModule,
];

@NgModule({
  imports: materialModules,
  exports: materialModules,
  providers: [
    {
      provide: MAT_ICON_DEFAULT_OPTIONS,
      useValue: { fontSet: 'material-symbols-rounded' },
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      // As of 2023-10-14, tooltips prevent scrolling on mobile.
      // See https://github.com/angular/components/issues/25566.
      useValue: { touchGestures: true },
    },
  ],
})
export class AppMaterialModule {}
