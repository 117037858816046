<div *ngIf="member">
  <div>
    <app-community-member-form
      [isCommunityAdmin$]="isCommunityAdmin$"
      (cancel)="cancel()"
      (save)="save($event)"
      [saving]="saving"
      [member]="memberSnapshot"
      [user]="userSnapshot"
      [title]="'Edit profile'">
    </app-community-member-form>
  </div>
</div>
