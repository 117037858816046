import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { avatars } from '@env';

@Component({
  selector: 'app-community-member-avatar',
  templateUrl: './community-member-avatar.component.html',
  styleUrls: ['./community-member-avatar.component.css'],
})
export class CommunityMemberAvatarComponent implements OnChanges, OnInit {
  @Input() avatar!: string;
  @Input() display = 'profile'; // profile, large

  @Input() height = 86;
  @Input() width = 86;

  avatarPath!: string;

  ngOnInit(): void {
    this.getAvatarPath();
  }

  ngOnChanges(): void {
    this.getAvatarPath();
    this.updateAvatarSize();
  }

  updateAvatarSize(): void {
    // profile images are SVGs and should be displayed at 86x86
    if (this.display === 'profile') {
      this.height = 86;
      this.width = 86;
    }
  }

  getAvatarPath(): void {
    let avatar = avatars[this.avatar];

    if (!avatar) {
      avatar = avatars.astro;
    }

    this.avatarPath = `avatars/${avatar[this.display]}`;
  }
}
