import { CommunityMember } from './community-member.model';

export const displayMemberFullNameAndEmail = (
  member: CommunityMember,
): string =>
  member ? `${member.firstName} ${member.lastName} (${member.email})` : '';

export const findActiveCommunityMembership = (
  communityKey: string,
  communityMembers: CommunityMember[],
): CommunityMember | undefined =>
  communityMembers.find(
    (member: CommunityMember) =>
      communityKey === member.communityKey && member.isActive,
  );
