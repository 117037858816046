<div class="app-table-container flex flex-col max-h-full">
  <div *ngIf="title">
    <h4 class="mb-2">{{ title }}</h4>
  </div>

  <div *ngIf="showSearch" class="app-search-container text-base">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search..." #input>
    </mat-form-field>
  </div>

  <mtx-grid class="flex-1 min-h-0 overflow-auto"
    [data]="(list | async) || []"
    [columns]="columns"
    [loading]="isLoading"
    [cellTemplate]="cellTemplate || {}"
    [cellSelectable]="false"
    [pageOnFront]="false"
    [rowHover]="true"
    [showPaginator]="false"
  ></mtx-grid>

  <ng-template #statusTpl let-row let-index="index" let-col="colDef">
    <mat-slide-toggle [checked]="row.isActive" color="primary"></mat-slide-toggle>
  </ng-template>
</div>
