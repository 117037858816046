import { Injectable } from '@angular/core';
import { CollectionReference } from '@angular/fire/compat/firestore';
import { lastValueFrom, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { User } from '@app/users/shared';
import { UserService } from '@app/users/shared/user.service';
import { AngularFirestoreService } from '@core/firebase/angular-firestore.service';
import { AuthService } from '@core/services/auth.service';
import { Community } from './community.model';
import { CommunityService } from './community.service';
import { CommunityMember, CommunityMembers } from './community-member.model';

@Injectable({
  providedIn: 'root',
})
export class CommunityMemberService {
  communitiesPath = '/communities';
  communityMembersPath = '/communityMembers';

  constructor(
    private afs: AngularFirestoreService,
    private authService: AuthService,
    private communityService: CommunityService,
    private userService: UserService,
  ) {}

  communityMemberPath = (memberKey: string): string =>
    `${this.communityMembersPath}/${memberKey}`;

  activateMember(
    community: Community,
    member: Partial<CommunityMember>,
    user?: User,
    password: string = '',
  ): Promise<CommunityMember> {
    // default member to active
    let memberToCreate = { ...member, isActive: true } as CommunityMember;

    if (member?.email) {
      memberToCreate.contactEmail = member.email;
    }

    if (user) {
      // if we already have a user, copy their properties first
      memberToCreate = {
        ...CommunityMember.fromUser(user),
        ...member,
      };
    }

    memberToCreate.communityKey = community.key; // assign them to the current community

    return this.createUserIfNecessary(memberToCreate, user, password).then(
      (dbUser) => {
        if (dbUser && dbUser.key) {
          memberToCreate.userKey = dbUser.key;
        }
        // return this.addMember(memberToCreate);
        return this.createMemberIfNecessary(memberToCreate);
      },
    );
  }

  async activateDefaultMembership(forUser?: User): Promise<boolean> {
    const user = forUser || this.authService.getUser();

    if (!user) {
      return false;
    }

    const activeCommunities = await lastValueFrom(
      this.getCommunityMembersForUser(user, true).pipe(take(1)),
    );

    if (activeCommunities.length) {
      return false;
    }

    const defaultCommunity = await this.communityService.initDefaultCommunity();
    const member = CommunityMember.fromUser(user, defaultCommunity);
    await this.addMember(member);

    return true;
  }

  addMember(member: CommunityMember): Promise<CommunityMember> {
    const databasePath = this.communityMembersPath;
    return this.afs.add<CommunityMember>(databasePath, member);
  }

  constructCommunityMembers(members: CommunityMember[]): CommunityMembers {
    const currentUser = this.authService.getUser();
    return new CommunityMembers(members, currentUser);
  }

  deactivateMember(member: CommunityMember): Promise<void> {
    // set the user inactive
    const inactiveMember: CommunityMember = { ...member, isActive: false };
    return this.set(inactiveMember);
  }

  getCommunityMember(key: string): Observable<CommunityMember> {
    return this.afs.doc(this.communityMemberPath(key));
  }

  getCommunityMembers(communityKey: string): Observable<CommunityMember[]> {
    const databasePath = this.communityMembersPath;
    return this.afs.collection<CommunityMember>(databasePath, (ref) =>
      ref.where('communityKey', '==', communityKey).orderBy('firstName'),
    );
  }

  getActiveCommunityMembers(
    communityKey: string,
  ): Observable<CommunityMember[]> {
    const databasePath = this.communityMembersPath;
    return this.afs.collection<CommunityMember>(databasePath, (ref) =>
      ref
        .where('communityKey', '==', communityKey)
        .where('isActive', '==', true)
        .orderBy('firstName'),
    );
  }

  getActiveCommunityMemberByUser(
    communityKey: string,
    userKey: string,
  ): Observable<CommunityMember> {
    const databasePath = this.communityMembersPath;
    return this.afs
      .collection<CommunityMember>(databasePath, (ref) =>
        ref
          .where('communityKey', '==', communityKey)
          .where('isActive', '==', true)
          .where('userKey', '==', userKey),
      )
      .pipe(
        filter(
          (communityMembers: CommunityMember[]) => !!communityMembers?.length,
        ),
        map((communityMembers: CommunityMember[]) => communityMembers[0]),
      );
  }

  getCommunityMembersForUser(
    user: User,
    activeOnly = false,
  ): Observable<CommunityMember[]> {
    // we will always be looking for community membership via email address
    return this.getCommunityMembersForUserByEmail(user, activeOnly);
  }

  getCommunityMembersForUserByKey(user: User): Observable<CommunityMember[]> {
    const databasePath = this.communityMembersPath;

    return this.afs.collection<CommunityMember>(databasePath, (ref) =>
      ref.where('userKey', '==', user.key).orderBy('updatedAt', 'desc'),
    );
  }

  getCommunityMembersForUserByEmail(
    user: User,
    activeOnly = false,
  ): Observable<CommunityMember[]> {
    const databasePath = this.communityMembersPath;

    const baseQuery = (ref: CollectionReference) =>
      ref.where('email', '==', user.email).orderBy('updatedAt', 'desc');
    const activeOnlyQuery = (ref: CollectionReference) =>
      baseQuery(ref).where('isActive', '==', true);

    return this.afs.collection<CommunityMember>(
      databasePath,
      activeOnly ? activeOnlyQuery : baseQuery,
    );
  }

  set(member: CommunityMember): Promise<void> {
    const memberKey = member.key;

    if (!memberKey) {
      throw new Error('Cannot set CommunityMember with undefined primary key.');
    }

    return this.afs.set(`${this.communityMembersPath}/${memberKey}`, member);
  }

  updateCommunityMembership(
    communityKey: string,
    userKey: string,
    member: CommunityMember,
  ): Promise<void> {
    const databasePath = this.communityMemberPath(member.key);
    const updatedMember = { ...member, communityKey, userKey };
    return this.afs.set(databasePath, updatedMember);
  }

  // find members (by email) already associated with community
  // if any exist, set them to active
  // if not, add new member
  async createMemberIfNecessary(
    communityMember: CommunityMember,
  ): Promise<CommunityMember> {
    const existingMembers = await lastValueFrom(
      this.afs
        .collection<CommunityMember>(`${this.communityMembersPath}`, (ref) =>
          ref
            .where('communityKey', '==', communityMember.communityKey)
            .where('email', '==', communityMember.email),
        )
        .pipe(take(1)),
    );

    if (existingMembers.length) {
      const activeMember = { ...existingMembers[0], isActive: true };
      await this.set(activeMember);
      return activeMember;
    }

    return this.addMember(communityMember);
  }

  createUserIfNecessary(
    member: CommunityMember,
    user?: User,
    password: string = '',
  ): Promise<User | undefined> {
    if (password) {
      // only create a new user if we are using password provider
      const userToCreate = CommunityMember.toUser(member);
      userToCreate.isGlobalAdmin = false; // don't allow new user to be created as admin
      return this.userService.create({ user: userToCreate, password });
    }
    // otherwise we either already have a user or aren't going to create one just yet
    return Promise.resolve(user);
  }
}
