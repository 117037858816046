<div class="community-members-container">
  <mat-card class="members-table-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search members">
      <mat-icon matPrefix class="material-symbols-rounded search-icon">search</mat-icon>
    </mat-form-field>
    <p class="member-count" matTooltip="Total number of paid memberships">
      <strong>Members:</strong> {{ (members$ | async)?.length || '...' }}
    </p>
  </mat-card>

  <mat-card class="members-table-container">
    <mat-table [dataSource]="dataSource" matSort>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell class="cell-name" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell class="cell-name" *matCellDef="let row"> <div class="ellipsis">{{ row.firstName }} {{ row.lastName }}</div> </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
        <mat-cell *matCellDef="let row"> <div class="ellipsis">{{ row.email }}</div> </mat-cell>
      </ng-container>

      <!-- isCommunityAdmin Column -->
      <ng-container matColumnDef="isCommunityAdmin">
        <mat-header-cell class="cell-admin" *matHeaderCellDef mat-sort-header> Admin </mat-header-cell>
        <mat-cell class="cell-admin" *matCellDef="let row">
          <mat-slide-toggle
            [checked]="row.isCommunityAdmin"
            (change)="toggleCommunityAdmin(row)"
            (click)="$event.stopPropagation()"
            color="primary">
          </mat-slide-toggle>
        </mat-cell>
      </ng-container>

      <!-- Remove Column -->
      <ng-container matColumnDef="delete">
        <mat-header-cell class="cell-remove" *matHeaderCellDef mat-sort-header>Remove</mat-header-cell>
        <mat-cell class="cell-remove" *matCellDef="let row">
          <button mat-icon-button align="end" class="delete-button" (click)="removeConfirm(row, $event)">
            <mat-icon class="material-symbols-rounded delete-icon red" matTooltip="Remove member" matTooltipPosition="after" title="delete">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="memberSelected(row)">
      </mat-row>
    </mat-table>

    <app-buffer-bar *ngIf="loading"></app-buffer-bar>

    <div class="empty-message" *ngIf="!loading && dataSource.data.length === 0">
      No members found.
    </div>

    <div class="empty-message" *ngIf="dataSource.data.length > 0 && dataSource.filter && dataSource.filteredData.length === 0">
      No members found matching search filter.
    </div>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card>
</div>
