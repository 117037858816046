import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import isEqual from 'lodash/fp/isEqual';
import { SessionStorageService } from 'ngx-webstorage';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import {
  map,
  mergeMap,
  shareReplay,
  skipWhile,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { User } from '@app/users/shared';
import { AuthService } from '@core/services/auth.service';
import { GlobalErrorHandler } from '@core/services/global-error-handler.service';
import { Community } from './community.model';
import { CommunityService } from './community.service';
import { getMostRecentlyActiveCommunity } from './community.util';
import { CommunityMember } from './community-member.model';
import { CommunityMemberService } from './community-member.service';

@Injectable({
  providedIn: 'root',
})
export class CommunitySessionService {
  sessionStorageKey = 'communities';

  private activeCommunities$!: Observable<Community[]>;

  constructor(
    private communityService: CommunityService,
    private authService: AuthService,
    private communityMemberService: CommunityMemberService,
    private errorHandler: GlobalErrorHandler,
    private router: Router,
    private sessionStorage: SessionStorageService,
  ) {}

  getCommunities(): Observable<Community[]> {
    // if (!this.activeCommunities$) {
    //   this.activeCommunities$ = this.refreshSession();
    // }

    this.activeCommunities$ = this.refreshSession();
    return this.activeCommunities$;
  }

  getCommunitiesSnapshot(excludeDefault?: boolean): Community[] {
    return (this.sessionStorage.retrieve(this.sessionStorageKey) || []).filter(
      (community: Community) =>
        excludeDefault ? community.key !== '_DEFAULT' : true,
    );
  }

  getActiveCommunity(): Observable<Community> {
    return this.getCommunities().pipe(map(getMostRecentlyActiveCommunity));
  }

  getActiveCommunitySnapshot(): Community {
    const communitySessionSnapshot = this.getCommunitiesSnapshot();
    return getMostRecentlyActiveCommunity(communitySessionSnapshot);
  }

  refreshSession(): Observable<Community[]> {
    const user$ = this.authService.getUser$();

    return user$.pipe(
      skipWhile((user: User) => !user),
      switchMap((user: User) =>
        this.communityMemberService.getCommunityMembersForUser(user, true),
      ),
      skipWhile((memberships: CommunityMember[]) => !memberships?.length),
      takeWhile(this.verifyCommunityMemberExists),
      mergeMap((memberships: CommunityMember[]) =>
        this.getCommunitiesByCommunityMembers(memberships),
      ),
      tap(this.verifyCommunityMembership),
      map((communities: Community[]) =>
        this.storeCommunitySession(communities),
      ),
      shareReplay(1),
    );
  }

  sessionExists(): boolean {
    const communitySession = this.getCommunitiesSnapshot();

    return !!(communitySession && communitySession.length);
  }

  private getCommunitiesByCommunityMembers(
    communityMembers: CommunityMember[],
  ): Observable<Community[]> {
    return observableCombineLatest(
      communityMembers.map((community: CommunityMember) =>
        this.communityService.getCommunity(community.communityKey),
      ),
    ).pipe(
      map((communities: Community[]) =>
        communities.filter((community: Community) => {
          if (!community) {
            this.errorHandler.captureError(
              `Invalid community for user: ${this.authService.getUser().key}`,
            );
            return false;
          }

          return true;
        }),
      ),
    );
  }

  private storeCommunitySession(communities: Community[]): Community[] {
    const currentSession = this.getCommunitiesSnapshot();

    if (!isEqual(currentSession, communities)) {
      this.sessionStorage.store(this.sessionStorageKey, communities);
    }

    return communities;
  }

  private verifyCommunityMemberExists = (
    communityMembers: CommunityMember[],
  ) => {
    if (communityMembers.length) {
      return true;
    }

    console.warn(
      'CommunitySessionService.verifyCommunityMemberExists(): No community members found matching this user ID.',
    );

    // Clear session upon user authentication revocation
    // this.sessionStorage.clear();
    // this.router.navigate(['community', 'welcome']);
    // window.location.reload();
    return false;
  };

  private verifyCommunityMembership = (communities: Community[]) => {
    if (communities.length) {
      return true;
    }

    this.router.navigate(['community', 'welcome']);
    return false;
  };
}
