<div class="confirmation-dialog">
  <h1 mat-dialog-title>
    {{ title }}
  </h1>
  <mat-dialog-content class="dialog-text">
    {{ message }}
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="dismiss-button" color="primary" type="button"
            (click)="dialogRef.close()">
      {{ dismissButton }}
    </button>
    <button mat-button class="confirm-button" color="primary" type="button"
            (click)="dialogRef.close(true)">
      {{ confirmButton }}
    </button>
  </mat-dialog-actions>
</div>
