import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { Community } from '../../shared';

@Component({
  selector: 'app-community-nav-list',
  templateUrl: './community-nav-list.component.html',
  styleUrls: ['./community-nav-list.component.css'],
})
export class CommunityNavListComponent {
  @Input() communities$!: Observable<Community[]>;
  @Input() canDeleteCommunities$!: Observable<boolean>;

  @Output() deletePressed = new EventEmitter();

  filter!: string;

  deleteCommunity(community: Community, event?: UIEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.deletePressed.emit(community);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement)?.value;
    this.filter = filterValue.trim().toLowerCase();
  }

  filteredCommunities(communities: Community[]): Community[] {
    if (!this.filter) {
      return communities;
    }
    const filterExp = new RegExp(this.filter, 'i');
    return communities.filter(
      (c) => filterExp.test(c.name) || filterExp.test(c.key),
    );
  }
}
