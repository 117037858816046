/* eslint max-classes-per-file:["error", 2] -- Legacy */

import { User } from '@app/users/shared';
import { FirebaseAbstract } from '@core/firebase';
import { Community } from './community.model';

export class CommunityMember extends FirebaseAbstract {
  communityKey = ''; // community they are a part of
  userKey = ''; // userId of the user if they exist yet
  email = '';
  providerId = '';

  firstName = '';
  lastName = '';
  avatar = '';
  bio = '';
  birthday = '';
  contactEmail = '';
  gender = '';
  grade!: number;
  languageCode = '';
  photoUrl = '';

  isCommunityAdmin = false;
  isActive = true;

  hasAcceptedInvitation = false;

  static fromUser(user: User, community?: Community): CommunityMember {
    const member = {
      ...new CommunityMember(),
      communityKey: community?.key || '',
      userKey: user.key,
      email: user.email,
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      bio: user.bio || '',
      birthday: user.birthday || '',
      contactEmail: user.contactEmail || user.email,
      gender: user.gender || '',
      photoUrl: user.photoUrl || '',
      isCommunityAdmin: !!user.isGlobalAdmin,
      isActive: !!user.isActive,
    };

    return member;
  }

  static toUser(member: CommunityMember): User {
    const user = {
      ...new User(),
      email: member.email,
      firstName: member.firstName,
      lastName: member.lastName,
      bio: member.bio,
      birthday: member.birthday,
      contactEmail: member.contactEmail || member.email,
      gender: member.gender,
      key: member.userKey,
      photoUrl: member.photoUrl,
      isActive: member.isActive,
    };

    return user;
  }
}

export class CommunityMembers {
  all: CommunityMember[];
  me: CommunityMember;

  constructor(members: CommunityMember[], user?: User) {
    this.all = members;
    this.me = members.find((m) => m.email === user?.email) || members[0];
  }
}
