import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@core/services/auth.service';
import { gotoContactPage } from '@shared/util/global';
import {
  Community,
  CommunityMember,
  getMostRecentlyActiveCommunity,
  UserCommunity,
} from '../../shared';
import { CommunityMemberSessionService } from '../../shared/community-member-session.service';
import { CommunitySessionService } from '../../shared/community-session.service';

@Component({
  selector: 'app-community-menu',
  templateUrl: './community-menu.component.html',
  styleUrls: ['./community-menu.component.css'],
})
export class CommunityMenuComponent implements OnInit {
  @Output() selected = new EventEmitter();

  communities$!: Observable<UserCommunity>;
  member$!: Observable<CommunityMember>;

  gotoContactPage = gotoContactPage;

  constructor(
    private authService: AuthService,
    private communitySession: CommunitySessionService,
    private communityMemberService: CommunityMemberSessionService,
  ) {}

  ngOnInit(): void {
    this.communities$ = this.communitySession
      .getCommunities()
      .pipe(map(this.extractUserCommunities));
    this.member$ = this.communityMemberService.getActiveMember();
  }

  logout(): void {
    this.authService.hardLogout();
  }

  extractUserCommunities = (communities: Community[]): UserCommunity => {
    // break out a list of communities by how they relate to the user's current session
    const userCommunities = new UserCommunity();
    userCommunities.active = getMostRecentlyActiveCommunity(communities);
    userCommunities.inactive = communities.filter(
      (acct) => acct.key !== userCommunities.active.key,
    );

    return userCommunities;
  };
}
