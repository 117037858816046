import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripPassage',
})
export class StripPassagePipe implements PipeTransform {
  transform(value = ''): string {
    const shadow = document.implementation.createHTMLDocument('_shadow').body;
    shadow.innerHTML = value.replace(/<\/p><p>/g, ' ');
    const text = shadow.textContent || shadow.innerText || '';

    return text.replace(/\s+/g, ' ').trim();
  }
}
