import { Injectable, NgZone } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of as observableOf, timer } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/**
 * @description
 *
 * Provides a preloading strategy that incrementally preloads modules after a brief initial delay.
 *
 * ```
 * RouteModule.forRoot(routes, { preloadingStrategy: PreloadModulesWithDelay })
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class PreloadModulesWithDelay implements PreloadingStrategy {
  // Module preloading prevents Angular from stabilizing. Delay with care.
  defaultDelay = 3; // seconds
  preloadModuleCount = 0;

  constructor(private ngZone: NgZone) {}

  preload(route: Route, fn: () => Observable<unknown>): Observable<unknown> {
    return route.data?.preload === false
      ? observableOf(null)
      : this.loadRoute(route.data?.delay || this.calculateDelay(), fn);
  }

  // Delay 3 additional seconds for each lazy loaded route.
  private calculateDelay = (): number => {
    this.preloadModuleCount += 1;
    return (this.defaultDelay + this.preloadModuleCount * 3) * 1000;
  };

  private loadRoute(
    delay: number,
    fn: () => Observable<unknown>,
  ): Observable<unknown> {
    if (delay) {
      return this.ngZone.runOutsideAngular(() =>
        timer(delay).pipe(mergeMap(() => this.safeLoad(fn))),
      );
    }
    return this.safeLoad(fn);
  }

  private safeLoad(fn: () => Observable<unknown>): Observable<unknown> {
    return fn().pipe(catchError(() => observableOf(null)));
  }
}
