import { Pipe, PipeTransform } from '@angular/core';

// Use to convert Typescript enum into iterable object like
// {0: "First Enum Value", 1: "Second Enum Value"}.
// Useful as Typescript enum objects include non-numeric keys
// in addition to the those demonstrated above, e.g.
// {0: "First Enum Value", "First Enum Value"}
//
// Credit: https://stackoverflow.com/questions/35750059/select-based-on-enum-in-angular2
@Pipe({
  name: 'keys',
})
export class KeysPipe implements PipeTransform {
  transform(value: Record<string, unknown>): { key: number; value: unknown }[] {
    const keys = [];
    for (const enumMember in value) {
      if (!Number.isNaN(parseInt(enumMember, 10))) {
        keys.push({ key: parseInt(enumMember, 10), value: value[enumMember] });
        // Uncomment if you want log
        // console.log("enum member: ", value[enumMember]);
      }
    }
    return keys;
  }
}
