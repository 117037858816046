import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-community-invite-code',
  templateUrl: './community-invite-code.component.html',
  styleUrls: ['./community-invite-code.component.css'],
})
export class CommunityInviteCodeComponent {
  @Output() inviteCodeSubmission = new EventEmitter();

  inviteCode!: string;

  joinCommunity(): void {
    this.inviteCodeSubmission.emit(this.inviteCode);
  }
}
