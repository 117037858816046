import { Pipe, PipeTransform } from '@angular/core';

import { ordinalTransform } from '../util/transform';

/*
 * Append ordinal to number (e.g. "1st" position)
 * Usage:
 *   value | ordinal:keepNumber
 * Example:
 *   {{ 23 |  ordinal}}
 *   formats to: '23rd'
 * Example:
 *   {{ 23 |  ordinal:false}}
 *   formats to: 'rd'
 * https://gist.github.com/JonCatmull/da9c8bb7367f1f55a4080bbc525c09ac
 */
@Pipe({ name: 'ordinal' })
export class OrdinalPipe implements PipeTransform {
  transform = ordinalTransform;
}
