import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AngularFirestoreService } from '@core/firebase/angular-firestore.service';
import { AccessRequest } from './access-request.model';

@Injectable({
  providedIn: 'root',
})
export class AccessRequestService {
  accessRequestsPath = '/accessRequests';

  constructor(private afs: AngularFirestoreService) {}

  getAccessRequests(
    communityKey: string,
    userKey: string,
  ): Observable<AccessRequest[]> {
    return this.afs.collection<AccessRequest>(this.accessRequestsPath, (ref) =>
      ref
        .where('communityKey', '==', communityKey)
        .where('userKey', '==', userKey),
    );
  }

  getAccessRequestsByCommunityKey(
    communityKey: string,
  ): Observable<AccessRequest[]> {
    return this.afs.collection<AccessRequest>(this.accessRequestsPath, (ref) =>
      ref.where('communityKey', '==', communityKey).orderBy('createdAt'),
    );
  }

  addAccessRequest(accessRequest: AccessRequest): Promise<AccessRequest> {
    return this.afs.add<AccessRequest>(this.accessRequestsPath, accessRequest);
  }

  deleteAccessRequest(accessRequestKey: string): Promise<void> {
    return this.afs.delete(`${this.accessRequestsPath}/${accessRequestKey}`);
  }
}
