import { Injectable } from '@angular/core';
import isEqual from 'lodash/fp/isEqual';
import { SessionStorageService } from 'ngx-webstorage';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AuthService } from '@core/services/auth.service';
import { filterNullish } from '@shared/util';
import { Community } from './community.model';
import { CommunityMember } from './community-member.model';
import { CommunityMemberService } from './community-member.service';
import { CommunitySessionService } from './community-session.service';

@Injectable({
  providedIn: 'root',
})
export class CommunityMemberSessionService {
  sessionStorageKey = 'communityMember';

  private activeMember$!: Observable<CommunityMember>;

  constructor(
    private communityService: CommunitySessionService,
    private authService: AuthService,
    private communityMemberService: CommunityMemberService,
    private sessionStorage: SessionStorageService,
  ) {}

  getActiveMember(): Observable<CommunityMember> {
    // if (!this.activeMember$) {
    //   this.activeMember$ = this.refreshSession();
    // }

    this.activeMember$ = this.refreshSession();
    return this.activeMember$;
  }

  getActiveMemberSnapshot(): CommunityMember {
    return this.sessionStorage.retrieve(
      this.sessionStorageKey,
    ) as CommunityMember;
  }

  refreshSession(): Observable<CommunityMember> {
    const user = this.authService.getUser();

    return observableCombineLatest([
      this.communityService.getActiveCommunity(),
      this.communityMemberService.getCommunityMembersForUser(user, true),
    ]).pipe(
      map(([community, members]: [Community, CommunityMember[]]) =>
        members.find((m) => m.communityKey === community.key),
      ),
      filterNullish(),
      map((member: CommunityMember) => this.storeSession(member)),
      shareReplay(1),
    );
  }

  private storeSession(member: CommunityMember): CommunityMember {
    const currentSession = this.getActiveMemberSnapshot();

    if (!isEqual(currentSession, member)) {
      this.sessionStorage.store(this.sessionStorageKey, member);
    }

    return member;
  }
}
