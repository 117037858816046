import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  CommunityMember,
  displayMemberFullNameAndEmail,
} from '@app/community/shared';
import { CommunityMemberService } from '@app/community/shared/community-member.service';
import { CommunitySessionService } from '@app/community/shared/community-session.service';

@Component({
  selector: 'app-community-member-search',
  templateUrl: './community-member-search.component.html',
})
export class CommunityMemberSearchComponent implements OnInit, OnDestroy {
  @Input() id!: number;
  @Output() memberSelected = new EventEmitter();
  @ViewChild('auto', { static: true }) auto!: ElementRef;

  error: unknown;

  filteredMembers: CommunityMember[] = [];
  memberCtrl = new UntypedFormControl();
  members: CommunityMember[] = [];
  valueSub!: Subscription;

  // Enable matAutocomplete to display a different value than is stored
  displayFn = displayMemberFullNameAndEmail;

  constructor(
    private communityService: CommunitySessionService,
    private communityMemberService: CommunityMemberService,
  ) {}

  ngOnInit(): void {
    this.getMembers();

    this.valueSub = this.memberCtrl.valueChanges.subscribe((val) => {
      this.filteredMembers = val
        ? this.members.filter(
            (member) =>
              (member.firstName &&
                member.firstName.match(new RegExp(val, 'gi'))) ||
              (member.lastName &&
                member.lastName.match(new RegExp(val, 'gi'))) ||
              (member.email && member.email.match(new RegExp(val, 'gi'))),
          )
        : this.members;
    });
  }

  ngOnDestroy(): void {
    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }
  }

  getMembers(): void {
    this.communityService
      .getActiveCommunity()
      .pipe(
        switchMap((community) =>
          this.communityMemberService.getCommunityMembers(community.key),
        ),
      )
      .subscribe((members) => {
        if (this.filteredMembers) {
          this.members = members;
        } else {
          this.filteredMembers = members;
          this.members = members;
        }
      });
  }

  selectMember(): void {
    this.memberSelected.emit(this.memberCtrl.value);
    this.memberCtrl.reset();
  }
}
