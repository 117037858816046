import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-divider',
  templateUrl: './message-divider.component.html',
  styleUrls: ['./message-divider.component.css'],
})
export class MessageDividerComponent {
  @Input() message!: string;
}
