import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CommunityMember } from '@app/community/shared';
import { ConfirmationDialogService } from '@core/services/confirmation-dialog.service';

@Component({
  selector: 'app-community-member-list',
  templateUrl: './community-member-list.component.html',
  styleUrls: ['./community-member-list.component.css'],
})
export class CommunityMemberListComponent {
  @Input() canViewDeleteMemberButtons$!: Observable<boolean>;
  @Input() numberOfMembersToDisplay!: number;
  @Input() members: CommunityMember[] = [];

  @Output() delete = new EventEmitter();

  error: unknown;
  currentPage = 1;

  constructor(
    private router: Router,
    private confirmationDialogService: ConfirmationDialogService,
  ) {}

  deleteConfirm(member: CommunityMember, event?: UIEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.confirmationDialogService
      .confirm(
        'Delete member?',
        `${member.firstName} ${member.lastName} will be permanently deleted.`,
        'DELETE',
        'CANCEL',
      )
      .subscribe((res) => {
        if (res === true) {
          this.deleteMember(member);
        }
      });
  }

  deleteMember(member: CommunityMember, event?: UIEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.delete.emit(member);
  }

  gotoMemberDetail(member: CommunityMember): void {
    this.router.navigate(['community', 'member', member.key]);
  }
}
