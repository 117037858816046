import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { serverTimestamp } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { catchError, debounceTime, map, skipWhile } from 'rxjs/operators';

import { GlobalErrorHandler } from '@core/services/global-error-handler.service';
import { defaultFirestoreQueryLimit, sanitizeProperties } from '@shared/util';

@Injectable({
  providedIn: 'root',
})
export class AngularFirestoreService {
  debounceDuration = 5; // milliseconds
  // current timestamp (Unix epoch in milliseconds) as determined server-side by Firebase
  timestamp = serverTimestamp();

  constructor(
    public afs: AngularFirestore,
    public errorHandler: GlobalErrorHandler,
  ) {}

  collection<T>(databasePath: string, queryFn?: QueryFn): Observable<T[]> {
    const query = queryFn || defaultFirestoreQueryLimit;

    return this.afs
      .collection<T>(`${databasePath}`, query)
      .valueChanges()
      .pipe(
        debounceTime(this.debounceDuration),
        catchError(this.handleAngularFirestoreCollectionObservableError),
      );
  }

  doc<T>(databasePath: string): Observable<T> {
    // TODO: Beastly hack coerces "as unknown as Observable<T>" here, instead of defining doc() correctly as returning
    // Observable<T | undefined>. Please revisit in the future, as time allows.
    return this.afs
      .doc<T>(`${databasePath}`)
      .valueChanges()
      .pipe(
        debounceTime(this.debounceDuration),
        catchError(this.handleAngularFirestoreDocumentObservableError),
      ) as unknown as Observable<T>;
  }

  /**
   * @whatItDoes Executes a Firestore Document query and returns data directly from the server, bypassing the cache.
   * @description
   * Use sparingly. This method disables local caching and offline data access, which will break PWA functionality.
   * When in doubt, default to using the doc() method for querying documents with caching enabled and live updates.
   */
  get<T>(databasePath: string): Observable<T> {
    return this.afs
      .doc<T>(`${databasePath}`)
      .get({ source: 'server' })
      .pipe(
        skipWhile((doc) => doc.metadata.fromCache),
        map((doc) => doc.data() as T),
        catchError(this.handleAngularFirestoreDocumentObservableError),
      );
  }

  /**
   * @whatItDoes Executes a Firestore Collection query and returns data directly from the server, bypassing the cache.
   * @description
   * Use sparingly. This method disables local caching and offline data access, which will break PWA functionality.
   * When in doubt, default to using collection() instead to query documents with caching enabled and live updates.
   */
  getCollection<T>(databasePath: string, queryFn?: QueryFn): Observable<T[]> {
    const query = queryFn || defaultFirestoreQueryLimit;

    return this.afs
      .collection<T>(`${databasePath}`, query)
      .get({ source: 'server' })
      .pipe(
        skipWhile((querySnap) => querySnap.metadata.fromCache),
        map((querySnap) => querySnap.docs.map((doc) => doc.data())),
        catchError(this.handleAngularFirestoreCollectionObservableError),
      );
  }

  add<T>(databasePath: string, data: T): Promise<T> {
    const pushKey = this.afs.createId();
    const sanitizedData = sanitizeProperties<T>({
      ...data,
      key: pushKey,
      createdAt: this.timestamp,
      updatedAt: this.timestamp,
    });

    return this.afs
      .doc(`${databasePath}/${pushKey}`)
      .set(sanitizedData)
      .then(() => sanitizedData)
      .catch(this.handleAngularFirestorePromiseError);
  }

  set<T>(databasePath: string, data: T): Promise<void> {
    const sanitizedData = sanitizeProperties({
      ...data,
      updatedAt: this.timestamp,
    });

    return this.afs
      .doc(`${databasePath}`)
      .set(sanitizedData)
      .catch(this.handleAngularFirestorePromiseError);
  }

  update<T extends Partial<unknown>>(
    databasePath: string,
    data: T,
  ): Promise<void> {
    const sanitizedData = sanitizeProperties<T>({
      ...data,
      updatedAt: this.timestamp,
    });

    return this.afs
      .doc(`${databasePath}`)
      .update(sanitizedData)
      .catch(this.handleAngularFirestorePromiseError);
  }

  delete(databasePath: string): Promise<void> {
    return this.afs
      .doc(`${databasePath}`)
      .delete()
      .catch(this.handleAngularFirestorePromiseError);
  }

  handleAngularFirestoreCollectionObservableError = <T>(
    error: unknown,
    caught: Observable<T>,
  ): Observable<never> =>
    this.errorHandler.handleObservableError(
      error,
      caught,
      'AngularFirestoreCollectionObservableError',
    );

  handleAngularFirestoreDocumentObservableError = <T>(
    error: unknown,
    caught: Observable<T>,
  ): Observable<never> =>
    this.errorHandler.handleObservableError(
      error,
      caught,
      'AngularFirestoreDocumentObservableError',
    );

  handleAngularFirestorePromiseError = (error: unknown): Promise<never> =>
    this.errorHandler.handlePromiseError(error, 'AngularFirestorePromiseError');
}
