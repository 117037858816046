<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->

<p *ngIf="!member">
  <button mat-raised-button color="accent" (click)="onAddPress()">ADD MEMBER</button>
</p>
<mat-card *ngIf="member">
  <form appPasswordMatchValidator name="form" autocomplete="off" (ngSubmit)="submit()" #addMemberForm="ngForm">
    <mat-card-title>
      Add Member
    </mat-card-title>
    <mat-card-content>
      <p>
        <mat-form-field class="full-width">
          <input matInput #emailInput="ngModel" [(ngModel)]="member.email" (keyup)="email$.next(emailInput.value)" name="email" placeholder="Email address"
            type="email" pattern="[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+" required>
        </mat-form-field>
      </p>
      <div *ngIf="emailInput.valid">
        <p *ngIf="user">
          An community with this email address already exists. We will automatically invite them to join this community.</p>
        <div *ngIf="!user">
          <p>
            No user found with this email address. Fill out this form to create a new user.
          </p>
          <p>
            <mat-form-field>
              <input matInput [(ngModel)]="member.firstName" name="firstName" placeholder="First name" required>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <input matInput [(ngModel)]="member.lastName" name="lastName" placeholder="Last name" required>
            </mat-form-field>
          </p>
          <div>
            <label>Community Login Type: </label>
            <mat-radio-group [(ngModel)]="member.providerId" name="provider" required>
              <mat-radio-button class="login-type-radio-button" value="google.com">Google</mat-radio-button>
              <mat-radio-button class="login-type-radio-button" value="password">Password</mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="member.providerId === 'password'">
            <p>
              <mat-form-field>
                <input matInput [(ngModel)]="password" name="password" placeholder="Password" [type]="showPassword ? 'text' : 'password'"
                  minlength="6" required>
                <mat-icon matSuffix (click)="showPassword=!showPassword" class="material-symbols-rounded visibility" [class.visible]="showPassword">
                  {{ showPassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
              </mat-form-field>
            </p>
            <p>
              <mat-form-field>
                <input matInput ngModel name="confirm" placeholder="Confirm password" [type]="showPassword ? 'text' : 'password'" required>
                <mat-icon matSuffix (click)="showPassword=!showPassword" class="material-symbols-rounded visibility" [class.visible]="showPassword">
                  {{ showPassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
              </mat-form-field>
            </p>
          </div>
        </div>
      </div>
      <div class="permissions">
        <h3>Permissions</h3>
        <mat-checkbox [(ngModel)]="member.isCommunityAdmin" color="primary" name="isCommunityAdmin">
          Admin
        </mat-checkbox>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button type="button" (click)="clear()">CANCEL</button>
      <button mat-raised-button [disabled]="!addMemberForm.form.valid || saving" class="save-button" color="accent" disableRipple="true" type="submit">
        <span class="margin-auto" [fxHide]="saving">SAVE</span>
        <mat-progress-spinner [fxShow]="saving" class="margin-auto" [diameter]="29" mode="indeterminate" [strokeWidth]="2.5">
        </mat-progress-spinner>
      </button>
    </mat-card-actions>
  </form>
</mat-card>
