import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';

import { DefaultPageTitleStrategy } from '@core/services/page-title.strategy';
import { PreloadModulesWithDelay } from '@core/services/router-preloading.strategy';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    data: { preload: false },
  },
  {
    path: 'assignments',
    loadChildren: () =>
      import('./exam-assignments/exam-assignment.module').then(
        (m) => m.ExamAssignmentModule,
      ),
  },
  {
    path: 'classrooms',
    loadChildren: () =>
      import('./classrooms/classroom.module').then((m) => m.ClassroomModule),
  },
  {
    path: 'community',
    loadChildren: () =>
      import('./community/community.module').then((m) => m.CommunityModule),
  },
  {
    path: 'curriculum',
    loadChildren: () =>
      import('./curriculum/curriculum.module').then((m) => m.CurriculumModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'exams',
    loadChildren: () => import('./exams/exam.module').then((m) => m.ExamModule),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/report.module').then((m) => m.ReportModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./users/user.module').then((m) => m.UserModule),
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadModulesWithDelay,
    }),
  ],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: DefaultPageTitleStrategy }],
})
export class AppRoutingModule {}
