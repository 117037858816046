import { Community } from './community.model';

export const displayCommunityNameAndShortname = (
  community: Community,
): string => (community ? `${community.name} (${community.shortname})` : '');

export const getMostRecentlyActiveCommunity = (
  communities: Community[],
): Community => {
  if (communities?.length) {
    return communities[0];
  }

  throw new Error('No active Community found. Check your connection.');
};
