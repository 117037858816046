<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity, @angular-eslint/template/no-call-expression -->

<div class="app-container" [class.is-mobile]="tabletQuery.matches">
  <mat-toolbar class="primary-toolbar" color="primary">
    <span class="toolbar-left">
      <button mat-icon-button (click)="isActive() && sidenav.toggle()">
        <mat-icon class="material-symbols-rounded">menu</mat-icon>
      </button>
      <a class="title" routerLink="/" (click)="tabletQuery.matches && sidenav.close()">
        <span class="font-light">{{ title }} </span>
        <b [matTooltip]="env.production ? '' : 'Development / Staging'">Beta</b>
      </a>
    </span>
    <button mat-icon-button class="toolbar-button" [matMenuTriggerFor]="helpMenu">
      <mat-icon class="material-symbols-rounded">help_outline</mat-icon>
    </button>
    <button mat-icon-button *ngIf="authenticated()" class="toolbar-button" [matMenuTriggerFor]="optionsMenu">
      <mat-icon class="material-symbols-rounded">more_vert</mat-icon>
    </button>
  </mat-toolbar>

  <mat-menu #helpMenu="matMenu">
    <a class="help-menu-link" href="{{ cwsUrl }}/contact" target="_blank">
      <button mat-menu-item>
        Contact us
      </button>
    </a>
    <a class="help-menu-link" href="{{ cwsUrl }}/termsofservice" target="_blank">
      <button mat-menu-item>
        Terms of service
      </button>
    </a>
  </mat-menu>

  <mat-menu xPosition="before" #optionsMenu="matMenu">
    <button mat-menu-item (click)="logout()">
      Log out
    </button>
  </mat-menu>

  <mat-sidenav-container
      fullscreen
      class="sidenav-container"
      [style.marginTop.px]="mobileQuery.matches ? 56 : 64">
    <mat-sidenav #sidenav
        [opened]="!!(activeCommunities$ | async)?.length && (tabletQuery.matches ? false : true)"
        [mode]="tabletQuery.matches ? 'over' : 'side'"
        [fixedInViewport]="tabletQuery.matches"
        [fixedTopGap]="mobileQuery.matches ? 56 : 64"
        fxLayout="column">
        <div class="nav-header" *ngIf="isActive()">
          <app-community-menu (selected)="tabletQuery.matches && sidenav.close()"></app-community-menu>
        </div>
      <div class="nav" fxFlex="auto">
        <div class="sidenav-group">
          <mat-nav-list>
            <a mat-list-item
                *ngFor="let view of sidenavViews"
                (click)="tabletQuery.matches && sidenav.close()"
                [routerLink]="[view.link]">
              <mat-icon mat-list-icon class="material-symbols-rounded" [class.material-symbols-filled]="view.filled">{{ view.icon }}</mat-icon>
              <span matLine>{{ view.name }}</span>
              <span matLine class="secondary">{{ view.description }}</span>
            </a>
          </mat-nav-list>
        </div>

        <div *ngIf="isGlobalAdmin()" class="sidenav-group">
          <h3 class="mb-2">Admin</h3>
          <mat-nav-list>
            <a mat-list-item
                *ngFor="let view of adminViews"
                (click)="tabletQuery.matches && sidenav.close()"
                [routerLink]="[view.link]">
              <mat-icon mat-list-icon class="material-symbols-rounded" [class.material-symbols-filled]="view.filled">{{ view.icon }}</mat-icon>
              <span matLine>{{ view.name }}</span>
              <span matLine class="secondary">{{ view.description }}</span>
            </a>
          </mat-nav-list>
        </div>
      </div>


    </mat-sidenav>

    <mat-sidenav-content class="content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<app-loading-spinner *ngIf="loading" @loadingTrigger></app-loading-spinner>
