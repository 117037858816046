import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

import { User } from '@app/users/shared';
import { AuthService } from './auth.service';

/**
 * All public methods should expose Observable<boolean> interfaces.
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private authService: AuthService) {}

  canActivateUser(): Observable<boolean> {
    return observableOf(this.isGlobalAdminSnapshot());
  }

  canDeleteUsers(): Observable<boolean> {
    return observableOf(this.isGlobalAdminSnapshot());
  }

  canEditUser(): Observable<boolean> {
    return observableOf(this.isGlobalAdminSnapshot());
  }

  canViewUserExams(user: User): Observable<boolean> {
    return observableOf(
      this.isGlobalAdminSnapshot() || this.getUserKey() === user.key,
    );
  }

  isGlobalAdmin(): Observable<boolean> {
    return observableOf(this.isGlobalAdminSnapshot());
  }

  getUserKey(): string {
    return this.authService.getUser().key;
  }

  isGlobalAdminSnapshot(): boolean {
    const currentUser = this.authService.getUser();
    const isGlobalAdmin = currentUser && currentUser.isGlobalAdmin;

    return !!isGlobalAdmin;
  }
}
