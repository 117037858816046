<div *ngIf="member" class="member-form">
  <form autocomplete="off" (ngSubmit)="submit()" #memberForm="ngForm">
    <mat-card-header>
      <mat-card-title>{{ title }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <table style="width: 100%" cellspacing="0">
        <tr>
          <td>
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>First name</mat-label>
              <input matInput [(ngModel)]="member.firstName" name="firstName" placeholder="First name" required>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Last name</mat-label>
              <input matInput [(ngModel)]="member.lastName" name="lastName" placeholder="Last name" required>
            </mat-form-field>
          </td>
        </tr>
      </table>

      <p>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Email address</mat-label>
          <input matInput [(ngModel)]="member.contactEmail" name="email" placeholder="Email address" type="email"
            pattern="[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+" required>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="outline" class="max-width-180">
          <mat-label>Birthday</mat-label>
          <input matInput [matDatepicker]="birthdayPicker" [ngModel]="member.birthday"
            (ngModelChange)="member.birthday=$event" name="birthday" placeholder="Birthday">
          <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
        </mat-form-field>
        <mat-datepicker #birthdayPicker></mat-datepicker>
      </p>

      <p>
        <mat-form-field appearance="outline" class="max-width-180">
          <mat-label>Accent / Dialect</mat-label>
          <mat-select [ngModel]="member.languageCode || 'en-US'" (ngModelChange)="member.languageCode=$event"
            name="languageCode" placeholder="Accent / Dialect">
            <mat-option *ngFor="let language of languages" [value]="language.value">
              {{ language.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Biography</mat-label>
          <textarea matInput [cdkTextareaAutosize]=true [cdkAutosizeMinRows]="5" [(ngModel)]="member.bio" name="bio"
            placeholder="Biography">
          </textarea>
        </mat-form-field>
      </p>


      <div *ngIf="isCommunityAdmin$ | async">
        <h5>Permissions</h5>
        <mat-checkbox [(ngModel)]="member.isCommunityAdmin" color="primary" name="isCommunityAdmin">
          Admin
        </mat-checkbox>
      </div>
    </mat-card-content>

    <mat-card-actions align="end">
      <button mat-button (click)="goBack()" type="button">CANCEL</button>
      <button mat-raised-button [disabled]="saving || !memberForm.form.valid" class="save-button" color="accent"
        disableRipple="true" type="submit">
        <span class="margin-auto" [fxHide]="saving">SAVE</span>
        <mat-progress-spinner [fxShow]="saving" class="margin-auto" [diameter]="29" mode="indeterminate"
          [strokeWidth]="2.5">
        </mat-progress-spinner>
      </button>
    </mat-card-actions>
  </form>
</div>
