import { HttpErrorResponse } from '@angular/common/http';

export const handleHttpError = (err: HttpErrorResponse): Promise<never> => {
  // We should consider remotely logging and monitoring application errors
  let errMsg: string;

  if (err.error instanceof Error) {
    // A client-side or network error occurred; handle it accordingly
    errMsg = `An HTTP error occurred: ${err.error.message}`;
  } else {
    // Backend returned an unsuccessful response code
    // Response body may contain clues as to what went wrong
    errMsg = `An HTTP error occurred. Code: ${err.status}. Body: ${
      err.error?.error || err.error
    }`;
  }

  console.error(errMsg);
  return Promise.reject(errMsg);
};
