import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  CollectionReference,
  QueryFn,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  skipWhile,
  switchMap,
} from 'rxjs/operators';

import { Community } from '@app/community/shared/';
import { CommunitySessionService } from '@app/community/shared/community-session.service';
import { defaultFirestoreQueryLimit, sanitizeProperties } from '@shared/util';
import { GlobalErrorHandler } from '../services/global-error-handler.service';
import { AngularFirestoreService } from './angular-firestore.service';

@Injectable({
  providedIn: 'root',
})
export class CommunityFirestoreService extends AngularFirestoreService {
  constructor(
    public communityService: CommunitySessionService,
    public override afs: AngularFirestore,
    public override errorHandler: GlobalErrorHandler,
  ) {
    super(afs, errorHandler);
  }

  override add<T>(databasePath: string, data: T): Promise<T> {
    const community = this.communityService.getActiveCommunitySnapshot();
    const pushKey = this.afs.createId();

    const sanitizedData = sanitizeProperties<T>({
      ...data,
      key: pushKey,
      communityKey: community.key,
      createdAt: this.timestamp,
      updatedAt: this.timestamp,
    });

    return this.afs
      .doc(`${databasePath}/${pushKey}`)
      .set(sanitizedData)
      .then(() => sanitizedData)
      .catch(this.handleAngularFirestorePromiseError);
  }

  override collection<T>(
    databasePath: string,
    queryFn?: QueryFn,
  ): Observable<T[]> {
    return this.communityService.getActiveCommunity().pipe(
      switchMap<Community, Observable<T[]>>((community: Community) =>
        this.communityCollection(community, databasePath, queryFn),
      ),
      debounceTime(this.debounceDuration),
      catchError(this.handleAngularFirestoreCollectionObservableError),
    );
  }

  /**
   * @whatItDoes Executes a Firestore Collection query and returns data directly from the server, bypassing the cache.
   * @description
   * Use sparingly. This method disables local caching and offline data access, which will break PWA functionality.
   * When in doubt, default to using collection() instead to query documents with caching enabled and live updates.
   */
  override getCollection<T>(
    databasePath: string,
    queryFn?: QueryFn,
  ): Observable<T[]> {
    return this.communityService.getActiveCommunity().pipe(
      switchMap((community: Community) =>
        this.communityGet(community, databasePath, queryFn),
      ),
      skipWhile((querySnap) => querySnap.metadata.fromCache),
      map((querySnap) => querySnap.docs.map((doc) => doc.data() as T)),
      catchError(this.handleAngularFirestoreCollectionObservableError),
    );
  }

  private communityCollection = <T>(
    community: Community,
    databasePath: string,
    queryFn?: QueryFn,
  ) =>
    this.afs
      .collection<T>(databasePath, this.communityQuery(community.key, queryFn))
      .valueChanges();

  private communityGet = (
    community: Community,
    databasePath: string,
    queryFn?: QueryFn,
  ) =>
    this.afs
      .collection(databasePath, this.communityQuery(community.key, queryFn))
      .get();

  private communityQuery =
    (communityKey: string, queryFn?: QueryFn): QueryFn =>
    (ref: CollectionReference) =>
      (queryFn || defaultFirestoreQueryLimit)(ref).where(
        'communityKey',
        '==',
        communityKey,
      );
}
