import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceWords',
})
export class SliceWordsPipe implements PipeTransform {
  transform(value: string | null, start: number, end?: number): string | null {
    if (value == null) {
      return value;
    }

    if (typeof value !== 'string') {
      throw new Error(`Invalid argument '${value}' for sliceWords pipe.`);
    }

    return value.split(' ').slice(start, end).join(' ');
  }
}
