import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { User } from '@app/users/shared';
import { AccessRequest } from '../../shared';

@Component({
  selector: 'app-community-pending-requests-data-table',
  templateUrl: './community-pending-requests-data-table.component.html',
  styleUrls: ['./community-pending-requests-data-table.component.css'],
})
export class CommunityPendingRequestsDataTableComponent
  implements AfterViewInit, OnInit
{
  @Input() accessRequests$!: Observable<AccessRequest[]>;

  @Output() approve = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns = ['name', 'email', 'approve'];
  dataSource: MatTableDataSource<AccessRequest>;
  loading = true;

  constructor() {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.accessRequests$
      .pipe(delay(1000)) // allow time for user queries to resolve
      .subscribe((accessRequests: AccessRequest[]) =>
        this.populateDataSource(accessRequests),
      );
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  populateDataSource(accessRequests: AccessRequest[]): void {
    this.dataSource.data = accessRequests.filter(
      (a: AccessRequest & Partial<{ user: User }>) =>
        !!(a && a.communityKey && a.user && a.userKey),
    );
    this.loading = false;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement)?.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  approveAccessRequest(accessRequest: AccessRequest, user: User): void {
    this.approve.emit({ accessRequest, user });
  }
}
