import { Location as AngularLocation } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { User } from '@app/users/shared';
import { CommunityMember } from '../../shared';
import { CommunityMemberService } from '../../shared/community-member.service';
import { CommunityPermissionService } from '../../shared/community-permission.service';

@Component({
  selector: 'app-community-member-edit-detail',
  templateUrl: './community-member-edit-detail.component.html',
  styleUrls: ['./community-member-edit-detail.component.css'],
})
export class CommunityMemberEditDetailComponent implements OnInit {
  @Input() member!: CommunityMember;
  @Input() user!: User;

  error: unknown;
  saving = false;
  canEditMember$!: Observable<boolean>;
  isCommunityAdmin$!: Observable<boolean>;
  memberSnapshot!: CommunityMember;
  userSnapshot!: User;

  constructor(
    private communityPermissionService: CommunityPermissionService,
    private communityMemberService: CommunityMemberService,
    private location: AngularLocation,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.canEditMember$ = this.communityPermissionService.canEditMember(
      this.member,
    );
    this.isCommunityAdmin$ = this.communityPermissionService.isCommunityAdmin();

    this.memberSnapshot = { ...this.member };
    this.userSnapshot = { ...this.user };
    this.saving = false;
  }

  goBack(): void {
    this.location.back();
  }

  navigateToStudentReport(): void {
    this.router.navigate(['reports/student', this.member.key]);
  }

  cancel(): void {
    this.router.navigate(['community/member', this.member.key]);
  }

  save({ member }: { member: CommunityMember; user: User }): void {
    this.saving = true;
    this.communityMemberService
      .set(member)
      .then(() => {
        // go to the profile
        this.router.navigate(['community/member', this.member.key]);
      })
      .catch((error) => (this.error = error));

    this.member = member;
  }
}
