import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
})
export class ImageComponent {
  @Input() path!: string;

  @Input() height!: number;
  @Input() width!: number;

  @Input() crop = 'lpad';
  @Input() quality = 'auto:low';
}
