import { Timestamp } from 'firebase/firestore';

import { Community } from './community.model';

export const defaultCommunity: Community = {
  key: '_DEFAULT',
  name: 'One World Schoolhouse',
  shortname: '_default',
  inviteCode: 'SCHOLYR',
  customerId: '',
  activeMembers: 0,
  discountPercent: 100,
  seats: 10000,
  isActive: true,
  hasOpenAccessPolicy: false,
  createdAt: Timestamp.fromMillis(1420092000000),
  updatedAt: Timestamp.fromMillis(1420092000000),
};
