import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
})
export class ConfirmationDialogComponent {
  title!: string;
  message!: string;
  confirmButton!: string;
  dismissButton!: string;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}
}
