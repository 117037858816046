<div *ngIf="communities$ | async; let communities; else loading">
  <div *ngIf="member$ | async; let member; else loading">
    <div class="nav-profile items-center">
      <a routerLink="/community/me">
        <div class="flex items-center overflow-hidden text-clip">
          <div class="menu-avatar">
            <app-community-member-avatar [avatar]="member.avatar" class="flex-none"></app-community-member-avatar>
          </div>
          <div class="ml-2 my-2">
            <h6 class="leading-tight">
              {{ member.firstName }} {{ member.lastName }}
            </h6>
            <div class="leading-tight text-sm">
              {{ communities.active.name }}
            </div>
          </div>
        </div>
      </a>
      <button mat-icon-button [matMenuTriggerFor]="communityMenu">
        <mat-icon color="primary" class="material-symbols-rounded material-symbols-filled">settings</mat-icon>
      </button>
    </div>


    <mat-menu #communityMenu="matMenu" [overlapTrigger]="false" xPosition="before">
      <div class="menu-container" (click)="selected.emit($event)">
        <div class="menu-item user-info" (click)="$event.stopPropagation()">
          <div>{{ member.firstName }} {{ member.lastName }}</div>
          <div class="user-email">{{ member.email }}</div>
        </div>
        <button mat-menu-item routerLink="/community/me">Profile</button>
        <button mat-menu-item (click)="gotoContactPage()">Help & feedback</button>
        <button mat-menu-item (click)="logout()">Log out</button>
        <div *ngIf="member.isCommunityAdmin">
          <mat-divider class="divider"></mat-divider>
          <div class="community-info menu-item" (click)="$event.stopPropagation()">
            <div>{{ communities.active.name }}</div>
            <div class="community-shortname">{{ communities.active.shortname }}</div>
          </div>
          <button mat-menu-item routerLink="/community/{{ communities.active.key }}">Community</button>
          <button mat-menu-item routerLink="/community/{{ communities.active.key }}/billing">Billing</button>
          <!-- <button mat-menu-item routerLink="/community/{{ communities.active.key }}/members/invites">Invite students</button> -->
          <button mat-menu-item routerLink="/community/{{ communities.active.key }}/members">Manage members</button>
          <button mat-menu-item routerLink="/reports">Reports</button>
        </div>
        <mat-divider class="divider"></mat-divider>
        <button mat-menu-item routerLink="/community/{{ inactive.key }}/join" *ngFor="let inactive of communities.inactive">Switch to <strong>{{ inactive.name }}</strong></button>
        <button mat-menu-item routerLink="/community/welcome">Switch to another community...</button>
      </div>
    </mat-menu>

  </div>
</div>

<ng-template #loading>
  <app-buffer-bar></app-buffer-bar>
</ng-template>
