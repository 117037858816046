import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Timestamp } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { default as firebaseCompat } from '@firebase/app-compat';
import { SessionStorageService } from 'ngx-webstorage';
import {
  defer,
  from as observableFrom,
  lastValueFrom,
  Observable,
  of as observableOf,
} from 'rxjs';
import { concatMap, map, shareReplay, take, tap } from 'rxjs/operators';

// Avoid circular dependency issues by importing dependencies directly
import { User } from '@app/users/shared/user.model';
import { AngularFirestoreService } from '@core/firebase/angular-firestore.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private firebaseUser!: firebase.User | null;
  private firebaseUser$: Observable<firebase.User | null>;
  private user!: User;
  private usersPath = '/users';

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestoreService,
    private router: Router,
    private sessionStorage: SessionStorageService,
  ) {
    this.firebaseUser$ = this.afAuth.authState.pipe(shareReplay(1));
    this.firebaseUser$.subscribe((firebaseUser: firebase.User | null) => {
      if (!firebaseUser) {
        this.setUser(null as unknown as User);
      }
      if (firebaseUser !== null) {
        this.firebaseUser = firebaseUser;
      }
    });
  }

  async customTokenLogin(
    token: string,
  ): Promise<firebase.auth.UserCredential | void> {
    this.clearSession();

    try {
      return await this.afAuth.signInWithCustomToken(token);
    } catch (error) {
      return console.error('Custom Token Login Failure: ', error);
    }
  }

  authenticated(): boolean {
    // @TODO: refactor this check
    // using `this.user` (an in-memory variable) to verify auth is not good™
    return !!(this.firebaseUser && this.user);
  }

  async createUser(user: User): Promise<User> {
    this.clearSession();

    try {
      const credential = await this.afAuth.createUserWithEmailAndPassword(
        user.email,
        user.password || '',
      );
      const firebaseUser = credential.user;
      const userKey = firebaseUser?.uid || '';

      const updateUser = {
        ...user,
        contactEmail: firebaseUser?.email,
        key: userKey,
        providerId: firebaseUser?.providerId,
        password: undefined,
        createdAt: this.afs.timestamp,
        updatedAt: this.afs.timestamp,
      };

      await this.afs.set(`${this.usersPath}/${userKey}`, updateUser);

      return updateUser as User;
    } catch (error) {
      console.error('Create User Failure: ', error);
      throw error;
    }
  }

  ensureUserAuthenticated(): Observable<boolean> {
    // make sure the user is logged in, refresh and set current user
    // if user is not authenticated, redirect
    return this.getAuthState().pipe(
      take(1),
      concatMap((authState) => this.refreshUser(authState)),
      concatMap((user) => this.saveUser(user)),
      map((user) => {
        if (user) {
          this.setUser(user);
        }

        if (user?.isActive) {
          return true;
        }

        if (user && !user.isActive) {
          this.router.navigate(['community', 'pending']);
          return false;
        }

        this.logout();
        this.router.navigate(['welcome', 'login']);
        return false;
      }),
    );
  }

  fetchUser(
    firebaseUser: firebase.User | null = this.firebaseUser,
  ): Observable<User> {
    // can't seem to inject user service here, for some reason - 1/15/17
    return this.afs.doc(`${this.usersPath}/${firebaseUser?.uid || ''}`);
  }

  getAuth(): AngularFireAuth {
    return this.afAuth;
  }

  getAuthState(): Observable<firebase.User | null> {
    return this.firebaseUser$;
  }

  getToken(): Promise<string> {
    return Promise.resolve(this.firebaseUser?.getIdToken() || '');
  }

  getUser(): User {
    return this.user;
  }

  getUser$(): Observable<User> {
    return this.getAuthState().pipe(
      concatMap((firebaseUser: firebase.User | null) =>
        this.refreshUser(firebaseUser),
      ),
      tap((user) => this.setUser(user)),
    );
  }

  googleLogin(): Promise<void> {
    this.clearSession();

    const provider = new firebaseCompat.auth.GoogleAuthProvider();
    provider.addScope('email');
    provider.addScope('profile');

    return this.afAuth.signInWithRedirect(provider).catch((error) => {
      console.error('Google Login Failure: ', error);
      throw error;
    });
  }

  hardLogout(): void {
    this.clearSession();

    // @TODO: figure out how to logout without forcing a hard reload
    this.logout().then(() => window.location.reload());
  }

  login(
    email: string,
    password: string,
  ): Promise<firebase.auth.UserCredential> {
    this.clearSession();

    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        console.error('User Login Failure: ', error);
        this.logout();
        throw error;
      });
  }

  logout(): Promise<boolean> {
    this.clearSession();

    return this.afAuth
      .signOut()
      .then(() => this.router.navigate(['welcome', 'login']));
  }

  refreshUser(
    firebaseUser: firebase.User | null = this.firebaseUser,
  ): Observable<User> {
    this.firebaseUser = firebaseUser;

    return defer(() =>
      firebaseUser ? this.fetchUser() : observableOf(null as unknown as User),
    );
  }

  saveUser(userRef: User): Observable<null> | Observable<User> {
    if (!userRef && !this.firebaseUser) {
      return observableOf(null);
    }
    if (userRef && userRef.email) {
      return observableOf(userRef);
    }

    const profile = this.firebaseUser;
    const user: User = this.user || {};

    if (profile?.displayName) {
      const nameArray = profile.displayName.split(' ');
      user.firstName = nameArray.slice(0, 1).join(' ');
      user.lastName = nameArray.slice(1).join(' ');
    } else {
      user.firstName = 'Unknown';
      user.lastName = 'Learner';
    }

    user.isActive = true; // new users are created active
    user.contactEmail = profile?.email ?? '';
    user.email = profile?.email ?? '';
    user.key = this.firebaseUser?.uid ?? '';
    user.photoUrl = profile?.photoURL ?? '';
    user.providerId = profile?.providerData[0]?.providerId ?? '';
    user.createdAt = this.afs.timestamp as Timestamp;
    user.updatedAt = this.afs.timestamp as Timestamp;

    return observableFrom(
      this.afs
        .set(`${this.usersPath}/${this.firebaseUser?.uid || ''}`, user)
        .then(() =>
          lastValueFrom(
            this.fetchUser().pipe(
              take(1),
              tap((u) => this.setUser(u)),
            ),
          ),
        ),
    );
  }

  setUser(user: User): void {
    this.user = user;
  }

  clearSession(): void {
    this.sessionStorage.clear();
  }
}
