import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MtxGridCellTemplate, MtxGridColumn } from '@ng-matero/extensions/grid';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
})
export class TableComponent implements OnInit {
  filter = new BehaviorSubject<string>('');
  list!: Observable<unknown[]>;
  isLoading = true;

  @Input() data!: Observable<unknown[]> | unknown[];
  @Input() columns: MtxGridColumn[] = [];
  @Input() cellTemplate: TemplateRef<any> | MtxGridCellTemplate | undefined;
  @Input() showSearch = true;
  @Input() title = '';

  @ViewChild('statusTpl', { static: true }) statusTpl!: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {
    if (Array.isArray(this.data)) {
      this.data = new BehaviorSubject<unknown[]>(this.data).asObservable();
    }

    this.list = combineLatest([this.data, this.filter]).pipe(
      map(([list, searchString]) =>
        searchString === ''
          ? list
          : list.filter((obj) => this.filterPredicate(obj, searchString)),
      ),
      tap(() => (this.isLoading = false)),
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const searchString = filterValue.trim().toLowerCase();
    this.filter.next(searchString);
  }

  filterPredicate: <T>(data: T, filter: string) => boolean = <T>(
    data: T,
    filter: string,
  ): boolean => {
    // Transform the data into a lowercase string of all property values.
    const dataStr = Object.keys(data as unknown as Record<string, any>)
      .reduce((currentTerm: string, key: string) => {
        // Use an obscure Unicode character to delimit the words in the concatenated string.
        // This avoids matches where the values of two columns combined will match the user's query
        // (e.g. `Flute` and `Stop` will match `Test`). The character is intended to be something
        // that has a very low chance of being typed in by somebody in a text field. This one in
        // particular is "White up-pointing triangle with dot" from
        // https://en.wikipedia.org/wiki/List_of_Unicode_characters
        return (
          currentTerm + (data as unknown as Record<string, any>)[key] + '◬'
        );
      }, '')
      .toLowerCase();

    // Transform the filter by converting it to lowercase and removing whitespace.
    const transformedFilter = filter.trim().toLowerCase();

    return dataStr.indexOf(transformedFilter) != -1;
  };
}
