import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { User } from '@app/users/shared';
import { UserService } from '@app/users/shared/user.service';
import { Community, CommunityMember } from '../../shared';
import { CommunityService } from '../../shared/community.service';

const memberDefaults = { isCommunityAdmin: false, providerId: 'google.com' };

@Component({
  selector: 'app-community-add-member',
  templateUrl: './community-add-member.component.html',
  styleUrls: ['./community-add-member.component.css'],
})
export class CommunityAddMemberComponent implements OnInit, OnDestroy {
  @Input() member: CommunityMember | null = null;
  @Input() resetForm$!: Subject<void>;
  @Input() saving = false;

  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();

  @ViewChild('addMemberForm') addMemberForm!: NgForm;

  user!: User; // stores the user associated with this member if they already exist
  email$ = new Subject<string>();
  password = '';
  showPassword = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private communityService: CommunityService,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.listenForFormResets();
    this.searchEmail(this.email$).subscribe(
      (foundUser) => (this.user = foundUser),
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  onAddPress(): void {
    this.member = { ...new CommunityMember(), ...memberDefaults };
  }

  submit(): void {
    // clear out password for other providers
    if (this.member?.providerId !== 'password') {
      this.password = '';
    }

    this.save.emit({
      member: this.member,
      user: this.user,
      password: this.password,
    });
  }

  clear(): void {
    this.member = null;
    this.cancel.emit();
  }

  getCommunity(): Observable<Community> {
    return this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.communityService.getCommunity(params.get('communityKey') || ''),
      ),
    );
  }

  findExistingUserByEmail(email: string): Observable<User> {
    return this.userService.getUsersByEmail(email).pipe(
      filter((users) => !!users.length),
      map((users) => users[0]),
    );
  }

  listenForFormResets(): void {
    this.resetForm$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.addMemberForm.resetForm();
      this.onAddPress();
    });
  }

  searchEmail(q: Observable<string>): Observable<User> {
    return q.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((email) => !!email),
      switchMap((email) => this.findExistingUserByEmail(email)),
      takeUntil(this.onDestroy$),
    );
  }
}
