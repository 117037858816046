import { AvatarOption } from '@shared/models';

export const avatars: { [name: string]: AvatarOption } = {
  astro: {
    profile: 'astro-profile_hofvtc.svg',
    large: 'astro-large_ibwnqn.png',
  },
  nova: {
    profile: 'nova-profile_uqge5p.svg',
    large: 'nova-large_auvnez.png',
  },
  avatar011: {
    profile: 'roundavatar01.1-profile_gnl61e.svg',
    large: 'avatar01.1-large_ietvfl.png',
  },
  avatar012: {
    profile: 'roundavatar01.2-profile_odnkr5.svg',
    large: 'avatar01.2-large_owd3l4.png',
  },
  avatar013: {
    profile: 'roundavatar01.3-profile_wsgtzl.svg',
    large: 'avatar01.3-large_fkkz9k.png',
  },
  avatar021: {
    profile: 'roundavatar02.1-profile_zcnlmy.svg',
    large: 'avatar02.1-large_njj5ks.png',
  },
  avatar022: {
    profile: 'roundavatar02.2-profile_mywjrr.svg',
    large: 'avatar02.2-large_uenskf.png',
  },
  avatar023: {
    profile: 'roundavatar02.3-profile_k6pmok.svg',
    large: 'avatar02.3-large_qfh1d6.png',
  },
  avatar031: {
    profile: 'roundavatar03.1-profile_qppjbo.svg',
    large: 'avatar03.1-large_mlmnvs.png',
  },
  avatar032: {
    profile: 'roundavatar03.2-profile_huxvkc.svg',
    large: 'avatar03.2-large_psp1hb.png',
  },
  avatar033: {
    profile: 'roundavatar03.3-profile_qlrwhx.svg',
    large: 'avatar03.3-large_tbj0kl.png',
  },
  avatar041: {
    profile: 'roundavatar04.1-profile_sytgrf.svg',
    large: 'avatar04.1-large_xcetja.png',
  },
  avatar042: {
    profile: 'roundavatar04.2-profile_mnpj3f.svg',
    large: 'avatar04.2-large_rplqfw.png',
  },
  avatar043: {
    profile: 'roundavatar04.3-profile_dpx2nh.svg',
    large: 'avatar04.3-large_ehdcpg.png',
  },
  avatar051: {
    profile: 'roundavatar05.1-profile_az2apt.svg',
    large: 'avatar05.1-large_ckmrsz.png',
  },
  avatar052: {
    profile: 'roundavatar05.2-profile_iid8e3.svg',
    large: 'avatar05.2-large_c0vuid.png',
  },
  avatar053: {
    profile: 'roundavatar05.3-profile_cqz1w7.svg',
    large: 'avatar05.3-large_ydzvby.png',
  },
  avatar061: {
    profile: 'roundavatar06.1-profile_iwwcpv.svg',
    large: 'avatar06.1-large_nxvawa.png',
  },
  avatar062: {
    profile: 'roundavatar06.2-profile_rrnvrs.svg',
    large: 'avatar06.2-large_wwpt5d.png',
  },
  avatar063: {
    profile: 'roundavatar06.3-profile_da4xft.svg',
    large: 'avatar06.3-large_hgmpqz.png',
  },
  avatar071: {
    profile: 'roundavatar07.1-profile_sqimm4.svg',
    large: 'avatar07.1-large_wjgx3y.png',
  },
  avatar072: {
    profile: 'roundavatar07.2-profile_zkyh9q.svg',
    large: 'avatar07.2-large_vlnyb8.png',
  },
  avatar073: {
    profile: 'roundavatar07.3-profile_hv4bxn.svg',
    large: 'avatar07.3-large_eixtre.png',
  },
  avatar081: {
    profile: 'roundavatar08.1-profile_aoumq2.svg',
    large: 'avatar08.1-large_c192mr.png',
  },
  avatar082: {
    profile: 'roundavatar08.2-profile_fsgbom.svg',
    large: 'avatar08.2-large_kddxy2.png',
  },
  avatar083: {
    profile: 'roundavatar08.3-profile_c8dz2u.svg',
    large: 'avatar08.3-large_flbdnp.png',
  },
  avatar091: {
    profile: 'roundavatar09.1-profile_eu04uv.svg',
    large: 'avatar09.1-large_jmzdmc.png',
  },
  avatar092: {
    profile: 'roundavatar09.2-profile_tzh1on.svg',
    large: 'avatar09.2-large_p7ftqh.png',
  },
  avatar093: {
    profile: 'roundavatar09.3-profile_bkvwwq.svg',
    large: 'avatar09.3-large_kkillz.png',
  },
  avatar0101: {
    profile: 'roundavatar10.1-profile_gpuuln.svg',
    large: 'avatar10.1-large_tv0gwh.png',
  },
  avatar0102: {
    profile: 'roundavatar10.2-profile_d2adw3.svg',
    large: 'avatar10.2-large_yedtmx.png',
  },
  avatar0103: {
    profile: 'roundavatar10.3-profile_puldoh.svg',
    large: 'avatar10.3-large_f6lw2z.png',
  },
  avatar0111: {
    profile: 'roundavatar11.1-profile_wopdkq.svg',
    large: 'avatar11.1-large_yqnmkv.png',
  },
  avatar0112: {
    profile: 'roundavatar11.2-profile_tut27y.svg',
    large: 'avatar11.2-large_fsq4jd.png',
  },
  avatar0113: {
    profile: 'roundavatar11.3-profile_vhygeh.svg',
    large: 'avatar11.3-large_piwhel.png',
  },
  avatar0121: {
    profile: 'roundavatar12.1-profile_khfnnq.svg',
    large: 'avatar12.1-large_zoqbz5.png',
  },
  avatar0122: {
    profile: 'roundavatar12.2-profile_mpijuz.svg',
    large: 'avatar12.2-large_tlpfpj.png',
  },
  avatar0123: {
    profile: 'roundavatar12.3-profile_lblb3d.svg',
    large: 'avatar12.3-large_fo2awd.png',
  },
};
