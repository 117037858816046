<div *ngIf="community$ | async; let community; else loading">
  <div class="add-member-container">
    <app-community-add-member
      [saving]="savingMember"
      [resetForm$]="resetForm$"
      (save)="createMember(community, $event)">
    </app-community-add-member>
  </div>

  <app-community-members-data-table
    [members$]="members$"
    (delete)="removeMember($event)"
    (update)="updateMember($event)">
  </app-community-members-data-table>
</div>

<ng-template #loading>
  <app-buffer-bar></app-buffer-bar>
</ng-template>
