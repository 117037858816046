import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AngularFirestoreService } from '@core/firebase/angular-firestore.service';
import { CloudFunctions } from '@core/firebase/cloud-functions.service';
import { webAppUrl } from '@env';
import { User } from './user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private usersPath = 'users';

  private createUserEndpoint = `${webAppUrl}/api/v1/createUser`;
  private deleteUserEndpoint = `${webAppUrl}/api/v1/deleteUser`;
  private updateUserEndpoint = `${webAppUrl}/api/v1/updateUser`;

  constructor(
    private afs: AngularFirestoreService,
    private cloudFunctions: CloudFunctions,
  ) {}

  getUser(userKey: string): Observable<User> {
    return this.afs.doc<User>(`${this.usersPath}/${userKey}`);
  }

  getUsers(): Observable<User[]> {
    return this.afs.collection(`${this.usersPath}`, (ref) =>
      ref.orderBy('firstName').limit(1000),
    );
  }

  getUsersByEmail(email: string): Observable<User[]> {
    return this.afs.collection(`${this.usersPath}`, (ref) =>
      ref.where('email', '==', email).orderBy('createdAt', 'desc'),
    );
  }

  create({
    user,
    password = '',
  }: {
    user: User;
    password: string;
  }): Promise<User> {
    return this.cloudFunctions.post<User>(this.createUserEndpoint, {
      user,
      password,
    });
  }

  delete(uid: string): Promise<void> {
    return this.cloudFunctions.post<void>(this.deleteUserEndpoint, { uid });
  }

  set({
    user,
    password = '',
  }: {
    user: User;
    password: string;
  }): Promise<void> {
    const uid: string = user.key;
    return this.cloudFunctions.put(this.updateUserEndpoint, {
      uid,
      user,
      password,
    });
  }
}
