/* eslint-disable @typescript-eslint/no-explicit-any -- Legacy */
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { environment } from '@env';
import { formatError } from '@shared/util/global';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  /**
   * @whatItDoes Silently captures errors and warnings in production.
   */
  captureError = (error: unknown): void => {
    if (environment.production) {
      this.reportError(error);
    }
  };

  override handleError(error: unknown): void {
    this.captureError(error);

    // rethrow exception to default error handler
    super.handleError(error);
  }

  handlePromiseError = (
    error: any,
    type: string = 'PromiseError',
  ): Promise<never> => {
    this.handleError(error);

    const errorDetails: string = error?.message
      ? error.message
      : error?.toString();
    const errorMessage = `${type}: ${errorDetails}`;

    return Promise.reject(errorMessage);
  };

  handleObservableError = (
    error: any,
    _caught: Observable<any>,
    type = 'ObservableError',
  ): Observable<never> => {
    this.handleError(error);

    const errorDetails: string =
      error && error.message ? error.message : error && error.toString();
    const errorMessage = `${type}: ${errorDetails}`;

    return observableThrowError(errorMessage);
  };

  handleWarning = (warning: unknown): void => {
    this.captureError(warning);
    console.warn(warning);
  };

  /**
   * @whatItDoes Reports fatal errors to Google Analytics.
   */
  reportError = (error: any): void => {
    const analytics =
      this.injector.get<AngularFireAnalytics>(AngularFireAnalytics);
    const errorMessage =
      error.message ||
      (error.ngDebugContext &&
        error.ngDebugContext.component &&
        error.ngDebugContext.component.constructor.name) ||
      'Unknown Error';

    analytics.logEvent('exception', {
      description: formatError(errorMessage || 'Unknown Error', error),
      fatal: true,
    });
  };
}
