import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AngularFirestoreService } from '@core/firebase/angular-firestore.service';
import { filterNullish } from '@shared/util';
import { Community } from './community.model';
import { defaultCommunity } from './default-community.model';

@Injectable({
  providedIn: 'root',
})
export class CommunityService {
  communitiesPath = '/communities';

  constructor(private afs: AngularFirestoreService) {}

  addCommunity(community: Community): Promise<Community> {
    const databasePath = this.communitiesPath;
    return this.afs.add<Community>(databasePath, community);
  }

  deleteCommunity(communityKey: string): Promise<void> {
    const databasePath = `${this.communitiesPath}/${communityKey}`;
    return this.afs.delete(databasePath);
  }

  getCommunity(communityKey: string): Observable<Community> {
    const databasePath = `${this.communitiesPath}/${communityKey}`;
    return this.afs.doc<Community>(databasePath);
  }

  getCommunities(): Observable<Community[]> {
    return this.afs.collection<Community>(this.communitiesPath);
  }

  getCommunityByShortname(shortname: string): Observable<Community> {
    return this.afs
      .collection<Community>(this.communitiesPath, (ref) =>
        ref.where('shortname', '==', shortname).limit(1),
      )
      .pipe(
        filterNullish(),
        map((communities: Community[]) => communities[0]),
      );
  }

  async initDefaultCommunity(): Promise<Community> {
    const community = await lastValueFrom(
      this.getCommunity('_DEFAULT').pipe(take(1)),
    );

    if (community) {
      return defaultCommunity;
    }

    const { key } = defaultCommunity;
    const databasePath = `${this.communitiesPath}/${key}`;
    await this.afs.set<Community>(databasePath, defaultCommunity);

    return defaultCommunity;
  }
}
