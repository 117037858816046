import {
  Observable,
  of as observableOf,
  OperatorFunction,
  pipe,
  UnaryFunction,
} from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Returns true if the object is a function.
 *
 * @param value The value to check
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy
export const isFunction = (value: any): value is (...args: any[]) => any =>
  typeof value === 'function';

export const getSymbolIterator = (): symbol => {
  if (typeof Symbol !== 'function' || !Symbol.iterator) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy
    return '@@iterator' as any;
  }

  return Symbol.iterator;
};

export const iterator = getSymbolIterator();

/** Identifies an input as being an Iterable */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy
export const isIterable = (input: any): input is Iterable<any> =>
  isFunction(input?.[iterator]);

/**
 * Tests to see if the object is "thennable".
 *
 * @param value the object to test
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy
export const isPromise = (value: any): value is PromiseLike<any> =>
  isFunction(value?.then);

export const filterNullish = <T>(): UnaryFunction<
  Observable<T | null | undefined>,
  Observable<T>
> =>
  pipe(
    filter((x) => x !== null && x !== undefined) as OperatorFunction<
      T | null | undefined,
      T
    >,
  );

// If all else fails, suppress errors in production to prevent leaking uncaught exception errors.
export const suppressListObservableError = (): Observable<never[]> =>
  observableOf([]);

// If all else fails, suppress errors in production to prevent leaking uncaught exception errors.
export const suppressObjectObservableError = (): Observable<null> =>
  observableOf(null);
