import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ConfirmationDialogService } from '@core/services/confirmation-dialog.service';
import { CommunityMember } from '../../shared';

@Component({
  selector: 'app-community-members-data-table',
  templateUrl: './community-members-data-table.component.html',
  styleUrls: ['./community-members-data-table.component.css'],
})
export class CommunityMembersDataTableComponent
  implements AfterViewInit, OnInit
{
  @Input() members$!: Observable<CommunityMember[]>;

  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns = ['name', 'email', 'isCommunityAdmin', 'delete'];
  dataSource: MatTableDataSource<CommunityMember>;
  loading = true;

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.members$.subscribe((members: CommunityMember[]) =>
      this.populateDataSource(members),
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement)?.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  memberSelected(member: CommunityMember): void {
    this.router.navigate(['community', 'member', member.key]);
  }

  populateDataSource(members: CommunityMember[]): void {
    this.dataSource.data = members;
    this.loading = false;
  }

  removeConfirm(member: CommunityMember, event?: UIEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.confirmationDialogService
      .confirm(
        'Remove member?',
        `${member.firstName} ${member.lastName} will be removed from the community.`,
        'REMOVE',
        'CANCEL',
      )
      .subscribe((res) => {
        if (res === true) {
          this.removeMember(member);
        }
      });
  }

  removeMember(member: CommunityMember): void {
    this.delete.emit(member);
  }

  toggleCommunityAdmin(member: CommunityMember): void {
    // eslint-disable-next-line no-param-reassign -- Legacy
    member.isCommunityAdmin = !member.isCommunityAdmin;
    this.updateMember(member);
  }

  updateMember(member: CommunityMember, event?: UIEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.update.emit(member);
  }
}
