import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval } from 'rxjs';

// Will countdown from <from> number and emit completed when done
@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.css'],
})
export class CountdownComponent implements OnInit {
  @Input() from!: number;
  @Output() completed = new EventEmitter();

  currentCount!: number;

  ngOnInit(): void {
    this.currentCount = Math.floor(this.from); // make sure it's not fractional

    this.startCountdown();
  }

  startCountdown(): void {
    const tick = interval(1000); // run countdown every second

    const subscription = tick.subscribe(() => {
      this.currentCount -= 1;

      if (this.currentCount <= 0) {
        this.completed.emit();
        subscription.unsubscribe();
      }
    });
  }
}
