<div class="community-invite-code-container">
  <form class="community-invite-code-form" (ngSubmit)="joinCommunity()" #communityInviteCodeForm="ngForm">
    <div class="community-invite-code-inner">
      <mat-form-field class="community-name-input-field" hideRequiredMarker>
        <input matInput
          #inviteCodeField="ngModel"
          [(ngModel)]="inviteCode"
          minlength="6"
          maxlength="6"
          name="inviteCode"
          placeholder="Invite code"
          required>
        <mat-hint align="end">{{ inviteCode && inviteCode.length || '0' }} / 6</mat-hint>
      </mat-form-field>
    </div>

    <button mat-raised-button
            class="join-button"
            color="primary"
            [disabled]="!communityInviteCodeForm.form.valid">
      JOIN
    </button>
  </form>
</div>
