<div class="community-access-requests-container">
  <mat-card class="access-requests-table-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search access requests">
      <mat-icon matPrefix class="material-symbols-rounded search-icon">search</mat-icon>
    </mat-form-field>
  </mat-card>

  <mat-card class="access-requests-table-container">
    <mat-table [dataSource]="dataSource" matSort>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell class="cell-name" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell class="cell-name" *matCellDef="let row"> {{ row.user?.firstName }} {{ row.user?.lastName }} </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.user?.email }} </mat-cell>
      </ng-container>

      <!-- Approve Column -->
      <ng-container matColumnDef="approve">
        <mat-header-cell class="cell-approve" *matHeaderCellDef mat-sort-header>Approve</mat-header-cell>
        <mat-cell class="cell-approve" *matCellDef="let row">
          <button *ngIf="row.user; let user" mat-icon-button align="end" class="approve-button" (click)="approveAccessRequest(row, user)">
            <mat-icon matTooltip="Add member" matTooltipPosition="after" title="approve" class="material-symbols-rounded">check_circle</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div *ngIf="loading">
      <app-buffer-bar></app-buffer-bar>
    </div>

    <div class="empty-message" *ngIf="!loading && dataSource.data.length === 0">
      No pending access requests.
    </div>

    <div class="empty-message" *ngIf="dataSource.data.length > 0 && dataSource.filter && dataSource.filteredData.length === 0">
      No access requests found matching search filter.
    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card>
</div>
