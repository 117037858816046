import { Injectable } from '@angular/core';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { PermissionService } from '@core/services/permission.service';
import { CommunityMember } from './community-member.model';
import { CommunityMemberSessionService } from './community-member-session.service';

/**
 * All public methods should expose Observable<boolean> interfaces.
 */
@Injectable({
  providedIn: 'root',
})
export class CommunityPermissionService {
  private isCommunityAdmin$!: Observable<boolean>;

  constructor(
    private communityMemberService: CommunityMemberSessionService,
    private permissionService: PermissionService,
  ) {}

  canDeleteCommunities(): Observable<boolean> {
    return this.permissionService.isGlobalAdmin();
  }

  canEditMember(member: CommunityMember): Observable<boolean> {
    const isActiveMember$ = this.communityMemberService
      .getActiveMember()
      .pipe(map((m) => m.key === member.key));

    return this.isCommunityAdminOr([isActiveMember$]);
  }

  isCommunityAdmin(): Observable<boolean> {
    if (!this.isCommunityAdmin$) {
      this.isCommunityAdmin$ = this.getCommunityAdminState().pipe(
        shareReplay(1),
      );
    }

    return this.isCommunityAdmin$;
  }

  isCommunityAdminAnd(observables: Observable<boolean>[]): Observable<boolean> {
    return observableCombineLatest([
      this.isCommunityAdmin(),
      ...observables,
    ]).pipe(
      map(([...args]) =>
        args.reduce(
          (sourcesAccumulator: boolean, source: boolean) =>
            !!(sourcesAccumulator && source),
        ),
      ),
    );
  }

  isCommunityAdminOr(observables: Observable<boolean>[]): Observable<boolean> {
    return observableCombineLatest([
      this.isCommunityAdmin(),
      ...observables,
    ]).pipe(
      map(([...args]) =>
        args.reduce(
          (sourcesAccumulator: boolean, source: boolean) =>
            !!(sourcesAccumulator || source),
        ),
      ),
    );
  }

  // Returns true if user is an admin of the current community or a global admin.
  isCommunityAdminSnapshot = ([communityMember, isGlobalAdmin]: [
    CommunityMember,
    boolean,
  ]): boolean =>
    !!(communityMember && communityMember.isCommunityAdmin) || !!isGlobalAdmin;

  private getCommunityAdminState(): Observable<boolean> {
    return observableCombineLatest([
      this.communityMemberService.getActiveMember(),
      this.permissionService.isGlobalAdmin(),
    ]).pipe(map(this.isCommunityAdminSnapshot));
  }
}
