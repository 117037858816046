<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->

<div class="error" *ngIf="error">{{ error }}</div>

<div class="member-list">
  <mat-nav-list dense>
    <a mat-list-item *ngFor="let member of members | paginate: { itemsPerPage: numberOfMembersToDisplay,
                                                currentPage: currentPage,
                                                id: 'memberListControls' }" class="member-list-item" (click)="gotoMemberDetail(member)">
      <img mat-list-avatar *ngIf="member.photoUrl" src="{{ member.photoUrl }}" alt="Picture of {{ member.firstName }} {{ member.lastName }}.">
      <mat-icon mat-list-icon *ngIf="!member.photoUrl" class="material-symbols-rounded">person_outline</mat-icon>
      <span matLine *ngIf="member.firstName || member.lastName" class="member-name">
        {{ member.firstName }} {{ member.lastName }}
      </span>
      <span matLine *ngIf="member.email" class="member-email">
        {{ member.email }}
      </span>
      <div *ngIf="canViewDeleteMemberButtons$ | async" class="member-list-actions">
        <button mat-icon-button align="end" class="delete-button" (click)="deleteConfirm(member, $event)">
          <mat-icon class="material-symbols-rounded delete-icon red">delete</mat-icon>
        </button>
      </div>
    </a>
  </mat-nav-list>
  <div fxLayoutAlign="center center">
    <pagination-controls id="memberListControls" class="member-list-pagination-controls" (pageChange)="currentPage = $event" [autoHide]="true"
      previousLabel="" nextLabel="">
    </pagination-controls>
  </div>
</div>
