const ordinals: string[] = ['th', 'st', 'nd', 'rd'];

/** Coerces a data-bound value (typically a string) to a boolean. */
export const coerceBooleanProperty = (value: unknown): boolean =>
  value != null && `${value}` !== 'false';

export const ordinalTransform = (
  n: number | string,
  keepNumber: boolean = true,
): string => {
  const integer = parseInt(`${n}`, 10);
  const v = integer % 100;
  return (
    (keepNumber ? integer : '') +
    (ordinals[(v - 20) % 10] || ordinals[v] || ordinals[0])
  );
};
