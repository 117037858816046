<div *ngIf="title" class="title-heading">
  <span class="title-heading-text">{{ title }}</span>
</div>
<div class="spinner-wrapper">
  <div class="sk-folding-cube">
    <div class="sk-cube1 sk-cube"></div>
    <div class="sk-cube2 sk-cube"></div>
    <div class="sk-cube4 sk-cube"></div>
    <div class="sk-cube3 sk-cube"></div>
  </div>
</div>
