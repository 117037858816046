import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Community, displayCommunityNameAndShortname } from '../../shared';

@Component({
  selector: 'app-community-autocomplete',
  templateUrl: './community-autocomplete.component.html',
})
export class CommunityAutocompleteComponent implements OnInit {
  @Input() communities: Community[] | null = [];
  @Output() communitySelected = new EventEmitter();

  communityAutocomplete: UntypedFormControl = new UntypedFormControl();
  filteredCommunities!: Observable<Community[]>;

  displayFn = displayCommunityNameAndShortname;

  ngOnInit(): void {
    // clear selection whenever input field is modified
    this.communityAutocomplete.valueChanges.subscribe(() =>
      this.communitySelected.emit(undefined),
    );

    this.filteredCommunities = this.communityAutocomplete.valueChanges.pipe(
      startWith<Community | string>(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => {
        if (name) {
          return this.filter(name);
        }
        return this.communities ? this.communities.slice() : [];
      }),
    );
  }

  filter(val: string): Community[] {
    return this.communities
      ? this.communities.filter(
          (community: Community) =>
            (community.name && community.name.match(new RegExp(val, 'gi'))) ||
            (community.shortname &&
              community.shortname.match(new RegExp(val, 'gi'))),
        )
      : [];
  }
}
