import { Component, Input } from '@angular/core';

import { coerceBooleanProperty } from '@shared/util';

@Component({
  selector: 'app-buffer-bar',
  templateUrl: './buffer-bar.component.html',
  styleUrls: ['./buffer-bar.component.css'],
})
export class BufferBarComponent {
  private isDense = false;

  /** Whether the list should display in dense mode or not. */
  @Input()
  get dense(): boolean {
    return this.isDense;
  }

  set dense(value: boolean) {
    this.isDense = coerceBooleanProperty(value);
  }
}
