import { Component } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { User } from '@app/users/shared';
import { UserService } from '@app/users/shared/user.service';
import { AccessRequest, CommunityMember } from '../../shared';
import { AccessRequestService } from '../../shared/access-request.service';
import { CommunityMemberService } from '../../shared/community-member.service';
import { CommunitySessionService } from '../../shared/community-session.service';

@Component({
  selector: 'app-community-pending-requests-tab',
  templateUrl: './community-pending-requests-tab.component.html',
  styleUrls: ['./community-pending-requests-tab.component.css'],
})
export class CommunityPendingRequestsTabComponent {
  accessRequests$: Observable<AccessRequest[]>;

  constructor(
    private accessRequestService: AccessRequestService,
    private communitySessionService: CommunitySessionService,
    private communityMemberService: CommunityMemberService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {
    this.accessRequests$ = this.getAccessRequests();
  }

  attachUser(
    accessRequest: AccessRequest &
      Partial<{ user: User; user$: Observable<User> }>,
  ): AccessRequest {
    /* eslint-disable no-param-reassign -- Legacy */
    accessRequest.user$ = this.userService
      .getUser(accessRequest.userKey || '')
      .pipe(first());
    accessRequest.user$.subscribe((user: User) => (accessRequest.user = user));
    /* eslint-enable no-param-reassign -- Legacy */
    return accessRequest;
  }

  getAccessRequests(): Observable<AccessRequest[]> {
    const paramMap = this.route.parent?.paramMap as Observable<ParamMap>;
    return paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.accessRequestService.getAccessRequestsByCommunityKey(
          params.get('communityKey') || '',
        ),
      ),
      map((accessRequests: AccessRequest[]) =>
        accessRequests.map((accessRequest: AccessRequest) =>
          this.attachUser(accessRequest),
        ),
      ),
    );
  }

  onAccessRequestApproval({
    accessRequest,
    user,
  }: {
    accessRequest: AccessRequest;
    user: User;
  }): void {
    const community = this.communitySessionService.getActiveCommunitySnapshot();

    this.communityMemberService
      .activateMember(community, null as unknown as CommunityMember, user)
      .then(() =>
        this.accessRequestService.deleteAccessRequest(accessRequest.key),
      )
      .then(() =>
        this.openSnackBar(`${user.email} has been added to your community.`),
      );
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, 'Dismiss', {
      duration: 3000,
    });
  }
}
